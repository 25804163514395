import React from "react";

import { Button, NonIdealState } from "@blueprintjs/core";
import { MainLayout } from "../../layouts/MainLayout";

interface IProps {
  onStart: () => void;
}

export const StartScreen: React.FunctionComponent<IProps> = ({ onStart }) => {
  const handleStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    onStart();
  };

  return (
    <MainLayout title="Entity labeling">
      <NonIdealState
        title="Ready to get started?"
        icon="endorsed"
        description="Are you ready to start working on moderation tasks? Press 'start' to begin."
        action={
          <Button intent="primary" large={true} onClick={handleStart}>
            Start now!
          </Button>
        }
      />
    </MainLayout>
  );
};
