import React from "react";

import { RouteComponentProps } from "react-router";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { AnonymousDashboard } from "./AnonymousDashboard";
import { LoggedInDashboard } from "./LoggedInDashboard";

export const ModerationDashboard: React.FunctionComponent<
  RouteComponentProps
> = props => {
  const [userSlug] = useLocalStorage("user_slug");

  if (userSlug) {
    return <LoggedInDashboard {...props} />;
  }

  return <AnonymousDashboard {...props} />;
};
