import { ApolloProvider } from "@apollo/react-hooks";
import React, { FunctionComponent } from "react";
import { client } from "../graphql/nes-next/client";
import { Router } from "./Router";

export const Root: FunctionComponent = () => {
  return (
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
  );
};
