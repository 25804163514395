import { Button, Callout, NumberRange } from "@blueprintjs/core";
import { OrderedSet } from "immutable";
import React, { useState } from "react";
import styled from "styled-components";
import {
  defaultCountry,
  defaultLanguage,
  defaultLocations,
  IGoogleCountry,
  IGoogleLanguage,
  IGoogleLocation
} from "../../data/serpApi";
import {
  IKeywordSearchInput,
  usePushKeywordSearchMutation
} from "../../graphql/product-discovery/api";
import { client } from "../../graphql/product-discovery/client";
import { MainLayout } from "../../layouts/MainLayout";
import { ErrorBoundary } from "../EntityModeration/ErrorBoundary";
import { KeywordForm } from "./KeywordForm";
import { Loader } from "./Loader";
import { ShoppingSearchForm } from "./ShoppingSearchForm";

const Wrapper = styled.div`
  display: grid;
  grid-gap: 18px;
  max-width: 600px;
  margin-bottom: 24px;
  margin: 0 auto;
`;

export const ProductDiscoveryKeywords: React.FunctionComponent = () => {
  const [
    createKeywordSearch,
    { loading, called, error }
  ] = usePushKeywordSearchMutation({ client });
  const [keywords, setKeywords] = useState(OrderedSet<string>([]));

  // Keyword search
  const [keywordLimit, setKeywordLimit] = useState(20);
  const [competitionRange, setCompetitionRange] = useState<NumberRange>([
    0,
    100
  ]);
  const [searchVolumeRange, setSearchVolumeRange] = useState<NumberRange>([
    0,
    3000
  ]);

  // Shopping search
  const [resultLimit, setResultLimit] = useState(4);
  const [country, setCountry] = useState<IGoogleCountry | null>(defaultCountry);
  const [language, setLanguage] = useState<IGoogleLanguage | null>(
    defaultLanguage
  );
  const [location, setLocation] = useState<IGoogleLocation | null>(
    defaultLocations[0]
  );

  const estimatedResultCount = resultLimit * keywordLimit * keywords.count();

  const handleAddKeyword = (values: string[]) =>
    setKeywords(prevKeywords => prevKeywords.concat(values));

  const handleDeleteKeyword = (keyword: string) =>
    setKeywords(prevKeywords => prevKeywords.delete(keyword));

  const handleSubmitKeywordSearch = async (
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.preventDefault();

    const countryCode = (country && country.country_code) || "nl";
    const languageCode = (language && language.language_code) || "nl";
    const locale = `${languageCode}_${countryCode.toUpperCase()}`;
    const [minimumSearchVolume, maximumSearchVolume] = searchVolumeRange;
    const [minimumCompetition, maximumCompetition] = competitionRange;

    for (const keyword of keywords.toArray()) {
      const input: IKeywordSearchInput = {
        keyword,
        location: locale,
        resultLimit: keywordLimit,
        minimumCompetition: minimumCompetition / 100,
        maximumCompetition: maximumCompetition / 100,
        minimumSearchVolume,
        ...(maximumSearchVolume === 3000 ? { maximumSearchVolume } : {}),
        shoppingSearch: {
          country: countryCode,
          language: language && language.language_code,
          resultLimit,
          location: location && location.id
        }
      };

      await createKeywordSearch({
        variables: { input }
      });
    }
  };

  const actions = (
    <Button intent="primary" fill={false} onClick={handleSubmitKeywordSearch}>
      Submit
    </Button>
  );

  const keywordFormProps = {
    keywords,
    onAddKeyword: handleAddKeyword,
    onDeleteKeyword: handleDeleteKeyword,
    limit: keywordLimit,
    onLimitChange: setKeywordLimit,
    searchVolumeRange,
    onSearchVolumeRangeChange: setSearchVolumeRange,
    competitionRange,
    onCompetitionRangeChange: setCompetitionRange
  };

  const shoppingSearchFormProps = {
    defaultLocations,
    estimatedResultCount,
    limit: resultLimit,
    onLimitChange: setResultLimit,
    location,
    onLocationChange: setLocation,
    country,
    onCountryChange: setCountry,
    language,
    onLanguageChange: setLanguage
  };

  if (called && loading) {
    return <Loader />;
  }

  if (called && error && !loading) {
    return (
      <Callout title="Error while creating keyword search" intent="danger">
        Something went wrong: <pre>{`${error}`}</pre>
      </Callout>
    );
  }

  return (
    <ErrorBoundary>
      <MainLayout title="Google Product Discovery" actions={actions}>
        <Wrapper>
          {estimatedResultCount > 3000 && (
            <Callout
              intent="warning"
              title="This search might overflow the queue with highly similar products"
            >
              Larger searches can prevent a diverse product catalog. Try
              limiting the number of keywords per seed, or decrease the number
              of products per derivate keyword. Your current search would result
              in around {estimatedResultCount} products.
            </Callout>
          )}

          <KeywordForm {...keywordFormProps} />
          <ShoppingSearchForm {...shoppingSearchFormProps} />
        </Wrapper>
      </MainLayout>
    </ErrorBoundary>
  );
};
