import {
  Button,
  Card,
  Classes,
  ControlGroup,
  H5,
  InputGroup,
  Keys
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import React, { FunctionComponent, useState } from "react";
import styled from "styled-components/macro";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { MainLayout } from "../../layouts/MainLayout";

const Container = styled.div`
  display: grid;
  min-height: 100%;
`;

const LoginForm = styled.div`
  display: grid;
  max-width: 400px;
  gap: 12px;
  align-self: center;
  justify-self: center;
`;

const useInputState = (initialValue: string = "") => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    onChange: (event: React.FormEvent<HTMLInputElement>) =>
      setValue(event.currentTarget.value)
  };
};

export const AnonymousDashboard: FunctionComponent = () => {
  const [, saveUserSlug] = useLocalStorage("user_slug");
  const userSlugInput = useInputState();

  const submit = () => {
    if (!userSlugInput.value) {
      return;
    }
    saveUserSlug(userSlugInput.value);
  };

  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    submit();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === Keys.ENTER) {
      event.preventDefault();
      submit();
    }
  };

  return (
    <MainLayout title="Sign in">
      <Container>
        <LoginForm>
          <Card elevation={3}>
            <H5>You have to be signed in to use this tool</H5>
            <p>
              For quality analysis and for payouts, we need to be able to
              identify who you are.
            </p>
            <ControlGroup>
              <InputGroup
                className={Classes.FILL}
                {...userSlugInput}
                onKeyDown={handleKeyDown}
                placeholder="Please enter your username..."
              />
              <Button
                intent="primary"
                onClick={handleSubmit}
                rightIcon={IconNames.ARROW_RIGHT}
              >
                Sign in
              </Button>
            </ControlGroup>
          </Card>
        </LoginForm>
      </Container>
    </MainLayout>
  );
};
