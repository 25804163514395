import { FormGroup } from "@blueprintjs/core";
import React, { FC, useMemo } from "react";
import Select from "react-select";
import { ValueType } from "react-select/src/types";
import {
  IProductFragment,
  IProductSkuFragment
} from "../../graphql/nexus-aliexpress/api";

interface IProps {
  onChange: (skuId: string | null) => void;
  optionId: string | null;
  product: IProductFragment;
}

const isOptionDisabled = (option: IProductSkuFragment) =>
  option.skuStock === false;
const getOptionValue = (option: IProductSkuFragment) => option.id;
const getOptionLabel = (option: IProductSkuFragment) =>
  (option.skuProperties || [])
    .map(
      sku =>
        `${sku.skuPropertyName}: ${sku.propertyValueDefinitionName ||
          sku.skuPropertyValue}`
    )
    .join(", ") +
  ` (${option.offerSaleDisplayPrice || option.skuDisplayPrice})${
    option.skuStock ? "" : " Out of stock"
  }`;

export const OptionSelector: FC<IProps> = ({ product, optionId, onChange }) => {
  const productSkus = product.productSkus;
  const value = useMemo(() => {
    if (!productSkus) {
      return null;
    }

    return productSkus.find(sku => sku.id === optionId);
  }, [productSkus, optionId]);

  const handleChange = (option: ValueType<IProductSkuFragment>) => {
    !option
      ? onChange(null)
      : Array.isArray(option)
      ? onChange(option[0].id)
      : onChange(option.id);
  };

  if (!productSkus) {
    return <div>No options selectable</div>;
  }

  return (
    <FormGroup label="Select product SKU:">
      <Select
        value={value}
        options={productSkus}
        onChange={handleChange}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isOptionDisabled={isOptionDisabled}
      />
    </FormGroup>
  );
};
