import React, { FunctionComponent } from "react";

import { NonIdealState } from "@blueprintjs/core";

export const Error: FunctionComponent = () => {
  return (
    <NonIdealState
      title="This doesn't seem right..."
      description="We're unable to show you tasks right now. You could try to refresh, but if it persists, please get in touch with us."
      icon="error"
    />
  );
};
