import React, { useEffect, useState } from "react";

import { Button, NavbarDivider } from "@blueprintjs/core";
import { Redirect } from "react-router";
import {
  IEntityTranslationAction,
  useRejectEntityTranslationsMutation,
  useSubmitEntityTranslationsMutation
} from "../../graphql/nes-next/api";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { MainLayout } from "../../layouts/MainLayout";
import { Error } from "./Error";
import { formStateToInputs } from "./helpers/formStateToInputs";
import { useConsumer } from "./helpers/useConsumer";
import { Loader } from "./Loader";
import { Product } from "./Product";
import { StartScreen } from "./StartScreen";

export interface IEntityFormState {
  entityId: string;
  value: string;
  action: IEntityTranslationAction;
}

export const ProductsQueue = () => {
  // Check user authentication
  const [userSlug] = useLocalStorage("user_slug");
  const [formError, setFormError] = useState(false);
  const [formState, setFormState] = useState<IEntityFormState[]>([]);
  const { queue, next, start, started, error } = useConsumer(userSlug);
  const [submitEntityTranslations] = useSubmitEntityTranslationsMutation();
  const [rejectEntityTranslations] = useRejectEntityTranslationsMutation();
  const [currentProduct] = queue;

  // Update formState when currentProduct changes
  useEffect(() => {
    if (!currentProduct) {
      return;
    }

    setFormState([]);
  }, [currentProduct]);

  if (error || formError) {
    return <Error />;
  }

  if (!userSlug) {
    return <Redirect to="/auth/login" />;
  }

  const handleSaveAndNext = () => {
    if (!currentProduct) {
      setFormError(true);
      return;
    }

    submitEntityTranslations({
      variables: {
        userSlug,
        productId: currentProduct.id,
        inputs: formStateToInputs(currentProduct, formState)
      }
    });
    next();
  };
  const handleDeleteAndNext = () => {
    rejectEntityTranslations({
      variables: {
        userSlug,
        productId: currentProduct.id
      }
    });
    next();
  };
  const handleStart = () => start();

  if (!started) {
    return <StartScreen onStart={handleStart} />;
  }

  const actions = (
    <>
      <Button intent="danger" minimal={true} onClick={handleDeleteAndNext}>
        Delete product and options
      </Button>

      <NavbarDivider />

      <Button intent="primary" onClick={handleSaveAndNext}>
        Save and Next
      </Button>
    </>
  );

  return (
    <MainLayout title="Entity labeling" actions={actions}>
      {currentProduct ? (
        <Product
          product={currentProduct}
          formState={formState}
          setFormState={setFormState}
        />
      ) : (
        <Loader />
      )}
    </MainLayout>
  );
};
