const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

export function siFormat(value: number, fractionDigits: number = 1) {
  // tslint:disable-next-line: no-bitwise
  const tier = (Math.log10(value) / 3) | 0;

  if (tier < 1) {
    return value;
  }

  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const formatted = (value / scale).toFixed(fractionDigits).replace(/.0+$/, "");

  return formatted + suffix;
}
