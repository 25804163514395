/* tslint:disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** 
 * The `Naive DateTime` scalar type represents a naive date and time without
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string.
 **/
  NaiveDateTime: any,
  RawJson: any,
  Cursor: any,
  /** 
 * The `Decimal` scalar type represents signed double-precision fractional
   * values parsed by the `Decimal` library.  The Decimal appears in a JSON
   * response as a string to preserve precision.
 **/
  Decimal: any,
  /** 
 * The `Date` scalar type represents a date. The Date appears in a JSON
   * response as an ISO8601 formatted string, without a time component.
 **/
  Date: any,
};

export enum IAccountStatus {
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED',
  ERROR_SIGNUP_FAILED = 'ERROR_SIGNUP_FAILED',
  VERIFICATION_EMAIL_TIMEOUT = 'VERIFICATION_EMAIL_TIMEOUT'
}

export enum IAccountType {
  BUYER = 'BUYER',
  CRAWLER = 'CRAWLER'
}

export type IAliexpressCreateOrder = {
  __typename?: 'AliexpressCreateOrder',
  logisticsService?: Maybe<IOrderLogisticsService>,
  orderId?: Maybe<Scalars['Int']>,
  productPriceCents?: Maybe<Scalars['Int']>,
};

export type IAliexpressMultimedia = {
  __typename?: 'AliexpressMultimedia',
  videos?: Maybe<Array<Maybe<IAliexpressMultimediaVideo>>>,
};

export type IAliexpressMultimediaVideo = {
  __typename?: 'AliexpressMultimediaVideo',
  aliMemberId?: Maybe<Scalars['ID']>,
  mediaId?: Maybe<Scalars['ID']>,
  mediaType?: Maybe<Scalars['String']>,
  posterUrl?: Maybe<Scalars['String']>,
};

export type IAliexpressOrder = {
  __typename?: 'AliexpressOrder',
  lineItems: Array<IOrderListItem>,
  createdAt: Scalars['NaiveDateTime'],
  logisticItems: Array<IOrderLogistic>,
  logisticsStatus: Scalars['String'],
  orderAmount: IMoney,
  orderStatus: Scalars['String'],
  storeInfo: IOrderStoreInfo,
};

export type IAliexpressOrderProductInput = {
  accountEmail?: Maybe<Scalars['String']>,
  address?: Maybe<IOrderAddressInput>,
  itemId?: Maybe<Scalars['Int']>,
  propertyValueIds?: Maybe<Array<Maybe<Scalars['Int']>>>,
  quantity?: Maybe<Scalars['Int']>,
  retailProductPriceCents?: Maybe<Scalars['Int']>,
  shippingFeeCents?: Maybe<Scalars['Int']>,
};

export type IAliexpressProduct = {
  __typename?: 'AliexpressProduct',
  multimedia?: Maybe<IAliexpressMultimedia>,
  productProperties?: Maybe<Array<IAliexpressProductProperty>>,
  productSkus?: Maybe<Array<IAliexpressSku>>,
  avgEvaluationRating?: Maybe<Scalars['String']>,
  categoryId?: Maybe<Scalars['ID']>,
  currencyCode?: Maybe<Scalars['String']>,
  deliveryTime?: Maybe<Scalars['Int']>,
  detail?: Maybe<Scalars['String']>,
  evaluationCount?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['NaiveDateTime']>,
  modifiedAt?: Maybe<Scalars['NaiveDateTime']>,
  grossWeight?: Maybe<Scalars['String']>,
  imageUrls?: Maybe<Array<Scalars['String']>>,
  itemOfferSiteSaleDisplayPrice?: Maybe<Scalars['String']>,
  itemOfferSiteSalePrice?: Maybe<Scalars['String']>,
  mobileDetail?: Maybe<Scalars['RawJson']>,
  orderCount?: Maybe<Scalars['Int']>,
  ownerMemberId?: Maybe<Scalars['ID']>,
  packageHeight?: Maybe<Scalars['Int']>,
  packageLength?: Maybe<Scalars['Int']>,
  packageType?: Maybe<Scalars['Boolean']>,
  packageWidth?: Maybe<Scalars['Int']>,
  productId?: Maybe<Scalars['ID']>,
  productStatusType?: Maybe<Scalars['String']>,
  productUnit?: Maybe<Scalars['Int']>,
  result?: Maybe<Scalars['RawJson']>,
  storeInfo?: Maybe<IProductStoreInfo>,
  subject?: Maybe<Scalars['String']>,
  totalAvailableStock?: Maybe<Scalars['Int']>,
  wsValidNum?: Maybe<Scalars['Int']>,
};

export type IAliexpressProductProperty = {
  __typename?: 'AliexpressProductProperty',
  attrName?: Maybe<Scalars['String']>,
  attrNameId?: Maybe<Scalars['ID']>,
  attrValue?: Maybe<Scalars['String']>,
  attrValueId?: Maybe<Scalars['ID']>,
};

export type IAliexpressProductSkuProperty = {
  __typename?: 'AliexpressProductSkuProperty',
  propertyValueDefinitionName?: Maybe<Scalars['String']>,
  propertyValueIdLong?: Maybe<Scalars['ID']>,
  skuImage?: Maybe<Scalars['String']>,
  skuPropertyId?: Maybe<Scalars['ID']>,
  skuPropertyName?: Maybe<Scalars['String']>,
  skuPropertyValue?: Maybe<Scalars['String']>,
};

export type IAliexpressSku = {
  __typename?: 'AliexpressSku',
  skuProperties?: Maybe<Array<IAliexpressProductSkuProperty>>,
  currencyCode?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  ipmSkuStock?: Maybe<Scalars['Int']>,
  offerSaleDisplayPrice?: Maybe<Scalars['String']>,
  offerSalePrice?: Maybe<Scalars['String']>,
  skuAvailableStock?: Maybe<Scalars['Int']>,
  skuCode?: Maybe<Scalars['String']>,
  skuDisplayPrice?: Maybe<Scalars['String']>,
  skuPrice?: Maybe<Scalars['String']>,
  skuStock: Scalars['Boolean'],
};

export type IAsset = {
  __typename?: 'Asset',
  downloadAttempts: Scalars['Int'],
  downloadDurationMs?: Maybe<Scalars['Int']>,
  downloadedAt?: Maybe<Scalars['NaiveDateTime']>,
  fileSize?: Maybe<Scalars['Int']>,
  foreignId: Scalars['ID'],
  id: Scalars['ID'],
  insertedAt: Scalars['NaiveDateTime'],
  source: IAssetSource,
  sourceUrl: Scalars['String'],
  status: IAssetStatus,
  storageUrl?: Maybe<Scalars['String']>,
  type: IAssetType,
  updatedAt: Scalars['NaiveDateTime'],
  uploadAttempts: Scalars['Int'],
  uploadDurationMs?: Maybe<Scalars['Int']>,
  uploadedAt?: Maybe<Scalars['NaiveDateTime']>,
};

export enum IAssetSource {
  CATEGORY_ATTRIBUTE = 'CATEGORY_ATTRIBUTE',
  DESCRIPTION = 'DESCRIPTION',
  GALLERY = 'GALLERY',
  OPTION = 'OPTION',
  PROPERTY = 'PROPERTY',
  REVIEW = 'REVIEW'
}

export enum IAssetStatus {
  DOWNLOAD_FAILED = 'DOWNLOAD_FAILED',
  DOWNLOADED = 'DOWNLOADED',
  DOWNLOADING = 'DOWNLOADING',
  PENDING = 'PENDING',
  UPLOAD_FAILED = 'UPLOAD_FAILED',
  UPLOADED = 'UPLOADED',
  UPLOADING = 'UPLOADING'
}

export enum IAssetType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO'
}

export type ICategory = {
  __typename?: 'Category',
  canonicalUrl: Scalars['String'],
  categoryTranslations: Array<ICategoryTranslation>,
  childCategories: Array<ICategory>,
  crawledAt?: Maybe<Scalars['NaiveDateTime']>,
  foreignId: Scalars['ID'],
  foreignParentId?: Maybe<Scalars['ID']>,
  id: Scalars['ID'],
  insertedAt: Scalars['NaiveDateTime'],
  pageCount?: Maybe<Scalars['Int']>,
  parentCategory?: Maybe<ICategory>,
  productListings: IProductListingConnection,
  refinePropertyIds: Array<Scalars['String']>,
  resultCount?: Maybe<Scalars['Int']>,
  updatedAt: Scalars['NaiveDateTime'],
};


export type ICategoryCategoryTranslationsArgs = {
  i18n?: Maybe<II18nInput>
};


export type ICategoryProductListingsArgs = {
  pagination: IPagination
};

export type ICategoryConnection = {
  __typename?: 'CategoryConnection',
  entries: Array<ICategory>,
  pageInfo: IPageInfo,
};

export type ICategoryTranslation = {
  __typename?: 'CategoryTranslation',
  id: Scalars['ID'],
  language: Scalars['String'],
  name: Scalars['String'],
  url: Scalars['String'],
};

export type ICreateUserInput = {
  accountType?: Maybe<IAccountType>,
  apiToken?: Maybe<Scalars['String']>,
  email: Scalars['String'],
  foreignId?: Maybe<Scalars['ID']>,
  password?: Maybe<Scalars['String']>,
};




export enum IGender {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

/** i18n */
export type II18nInput = {
  currency?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
};

export type IMoney = {
  __typename?: 'Money',
  /** The amount in decimals */
  amount: Scalars['String'],
  /** The ISO 4217 valid currency */
  currency: Scalars['String'],
  /** The currency symbol */
  currencySymbol: Scalars['String'],
  /** The currency and amount */
  display: Scalars['String'],
};


export type IOrderAddressInput = {
  address1?: Maybe<Scalars['String']>,
  address2?: Maybe<Scalars['String']>,
  city?: Maybe<Scalars['String']>,
  countryCode?: Maybe<Scalars['String']>,
  fullName?: Maybe<Scalars['String']>,
  mobileNumber?: Maybe<Scalars['String']>,
  mobileNumberCountryPrefix?: Maybe<Scalars['String']>,
  zip?: Maybe<Scalars['String']>,
};

export type IOrderDeliveryWindow = {
  __typename?: 'OrderDeliveryWindow',
  avg?: Maybe<Scalars['Int']>,
  max?: Maybe<Scalars['Int']>,
  min?: Maybe<Scalars['Int']>,
};

export type IOrderListItem = {
  __typename?: 'OrderListItem',
  productCount: Scalars['Int'],
  productId: Scalars['String'],
  productName: Scalars['String'],
  productPrice: IMoney,
};

export type IOrderLogistic = {
  __typename?: 'OrderLogistic',
  logisticsNumber: Scalars['String'],
  logisticsService: Scalars['String'],
};

export type IOrderLogisticsService = {
  __typename?: 'OrderLogisticsService',
  deliveryWindow?: Maybe<IOrderDeliveryWindow>,
  name?: Maybe<Scalars['String']>,
  priceCents?: Maybe<Scalars['Int']>,
};

export type IOrderStoreInfo = {
  __typename?: 'OrderStoreInfo',
  storeId: Scalars['ID'],
  storeName: Scalars['String'],
  storeUrl: Scalars['String'],
};

/** PageInfo */
export type IPageInfo = {
  __typename?: 'PageInfo',
  after?: Maybe<Scalars['String']>,
  before?: Maybe<Scalars['String']>,
  limit?: Maybe<Scalars['Int']>,
  totalCount?: Maybe<Scalars['Int']>,
  totalCountCapExceeded?: Maybe<Scalars['Boolean']>,
};

/** Pagination */
export type IPagination = {
  after?: Maybe<Scalars['Cursor']>,
  before?: Maybe<Scalars['Cursor']>,
  first?: Maybe<Scalars['Int']>,
  includeTotalCount?: Maybe<Scalars['Boolean']>,
  last?: Maybe<Scalars['Int']>,
};

export type IProduct = {
  __typename?: 'Product',
  canonicalUrl: Scalars['String'],
  category: ICategory,
  foreignCategoryId: Scalars['ID'],
  foreignId: Scalars['ID'],
  id: Scalars['ID'],
  insertedAt: Scalars['NaiveDateTime'],
  isLot: Scalars['Boolean'],
  isPreSale: Scalars['Boolean'],
  lotQuantity: Scalars['Int'],
  orderCount?: Maybe<Scalars['Int']>,
  productAssets: Array<IProductAsset>,
  productTranslations: Array<IProductTranslation>,
  properties: Array<IProperty>,
  purchaseLimit: Scalars['Int'],
  reviewPercentage?: Maybe<Scalars['Int']>,
  specifications: Array<ISpecification>,
  updatedAt: Scalars['NaiveDateTime'],
  variants: Array<IVariant>,
};


export type IProductProductTranslationsArgs = {
  i18n?: Maybe<II18nInput>
};

export type IProductAsset = {
  __typename?: 'ProductAsset',
  asset: IAsset,
  id: Scalars['ID'],
  isMain: Scalars['Boolean'],
  product: IProduct,
};

export type IProductConnection = {
  __typename?: 'ProductConnection',
  entries: Array<IProduct>,
  pageInfo: IPageInfo,
};

export type IProductListing = {
  __typename?: 'ProductListing',
  asset: IAsset,
  category: ICategory,
  currency: Scalars['String'],
  foreignCategoryId: Scalars['ID'],
  foreignId: Scalars['ID'],
  id: Scalars['ID'],
  insertedAt: Scalars['NaiveDateTime'],
  maxPrice?: Maybe<Scalars['Decimal']>,
  minPrice?: Maybe<Scalars['Decimal']>,
  orderCount?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['Decimal']>,
  product?: Maybe<IProduct>,
  reviewPercentage?: Maybe<Scalars['Int']>,
  storeId?: Maybe<Scalars['ID']>,
  storeName?: Maybe<Scalars['String']>,
  title: Scalars['String'],
  updatedAt: Scalars['NaiveDateTime'],
  url: Scalars['String'],
};

export type IProductListingConnection = {
  __typename?: 'ProductListingConnection',
  entries: Array<IProductListing>,
  pageInfo: IPageInfo,
};

export type IProductStoreInfo = {
  __typename?: 'ProductStoreInfo',
  communicationRating?: Maybe<Scalars['String']>,
  itemAsDescripedRating?: Maybe<Scalars['String']>,
  shippingSpeedRating?: Maybe<Scalars['String']>,
  storeId?: Maybe<Scalars['ID']>,
  storeName?: Maybe<Scalars['String']>,
};

export type IProductTranslation = {
  __typename?: 'ProductTranslation',
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  insertedAt: Scalars['NaiveDateTime'],
  language: Scalars['String'],
  mobileUrl?: Maybe<Scalars['String']>,
  oldUrl?: Maybe<Scalars['String']>,
  product: IProduct,
  title: Scalars['String'],
  updatedAt: Scalars['NaiveDateTime'],
  url: Scalars['String'],
};

export type IProperty = {
  __typename?: 'Property',
  foreignId: Scalars['ID'],
  id: Scalars['ID'],
  insertedAt: Scalars['NaiveDateTime'],
  order: Scalars['Int'],
  product: IProduct,
  propertyTranslations: Array<IPropertyTranslation>,
  propertyValues: Array<IPropertyValue>,
  updatedAt: Scalars['NaiveDateTime'],
};


export type IPropertyPropertyTranslationsArgs = {
  i18n?: Maybe<II18nInput>
};

export type IPropertyTranslation = {
  __typename?: 'PropertyTranslation',
  language: Scalars['String'],
  name: Scalars['String'],
};

export type IPropertyValue = {
  __typename?: 'PropertyValue',
  asset?: Maybe<IAsset>,
  color?: Maybe<Scalars['String']>,
  foreignId: Scalars['ID'],
  id: Scalars['ID'],
  insertedAt: Scalars['NaiveDateTime'],
  order: Scalars['Int'],
  property: IProperty,
  propertyValueTranslations: Array<IPropertyValueTranslation>,
  updatedAt: Scalars['NaiveDateTime'],
};


export type IPropertyValuePropertyValueTranslationsArgs = {
  i18n?: Maybe<II18nInput>
};

export type IPropertyValueTranslation = {
  __typename?: 'PropertyValueTranslation',
  definitionName?: Maybe<Scalars['String']>,
  displayName: Scalars['String'],
  language: Scalars['String'],
  name: Scalars['String'],
};


export type IRootMutationType = {
  __typename?: 'RootMutationType',
  aliexpressOrderProduct?: Maybe<IAliexpressCreateOrder>,
  /** Create a new User */
  createUser?: Maybe<IUserPayload>,
  /** Update an existing User */
  updateUser?: Maybe<IUserPayload>,
};


export type IRootMutationTypeAliexpressOrderProductArgs = {
  input?: Maybe<IAliexpressOrderProductInput>
};


export type IRootMutationTypeCreateUserArgs = {
  input: ICreateUserInput
};


export type IRootMutationTypeUpdateUserArgs = {
  id: Scalars['ID'],
  input: IUpdateUserInput
};

export type IRootQueryType = {
  __typename?: 'RootQueryType',
  aliexpressOrder?: Maybe<IAliexpressOrder>,
  aliexpressProduct: IAliexpressProduct,
  categories: ICategoryConnection,
  category?: Maybe<ICategory>,
  categoryByForeignId?: Maybe<ICategory>,
  product?: Maybe<IProduct>,
  products: IProductConnection,
  productsByCategoryId: IProductConnection,
  productsByForeignCategoryId: IProductConnection,
  version?: Maybe<Scalars['String']>,
};


export type IRootQueryTypeAliexpressOrderArgs = {
  email: Scalars['String'],
  orderId: Scalars['ID']
};


export type IRootQueryTypeAliexpressProductArgs = {
  email: Scalars['String'],
  foreignId: Scalars['ID'],
  locale?: Maybe<Scalars['String']>
};


export type IRootQueryTypeCategoriesArgs = {
  pagination: IPagination
};


export type IRootQueryTypeCategoryArgs = {
  id: Scalars['ID']
};


export type IRootQueryTypeCategoryByForeignIdArgs = {
  foreignId: Scalars['ID']
};


export type IRootQueryTypeProductArgs = {
  i18n?: Maybe<II18nInput>,
  id: Scalars['ID']
};


export type IRootQueryTypeProductsArgs = {
  pagination: IPagination
};


export type IRootQueryTypeProductsByCategoryIdArgs = {
  categoryId: Scalars['ID'],
  pagination: IPagination
};


export type IRootQueryTypeProductsByForeignCategoryIdArgs = {
  foreignCategoryId: Scalars['ID'],
  pagination: IPagination
};

export type ISpecification = {
  __typename?: 'Specification',
  foreignId?: Maybe<Scalars['ID']>,
  foreignValueId?: Maybe<Scalars['ID']>,
  id: Scalars['ID'],
  insertedAt: Scalars['NaiveDateTime'],
  language: Scalars['String'],
  name: Scalars['String'],
  product: IProduct,
  updatedAt: Scalars['NaiveDateTime'],
  value: Scalars['String'],
};

export type IUpdateUserInput = {
  accountType?: Maybe<IAccountType>,
  apiToken?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  foreignId?: Maybe<Scalars['ID']>,
  password?: Maybe<Scalars['String']>,
};

export type IUser = {
  __typename?: 'User',
  accountType: IAccountType,
  claimedAt?: Maybe<Scalars['NaiveDateTime']>,
  dateOfBirth?: Maybe<Scalars['Date']>,
  email: Scalars['String'],
  firstName: Scalars['String'],
  foreignId?: Maybe<Scalars['ID']>,
  gender: IGender,
  id: Scalars['ID'],
  insertedAt: Scalars['NaiveDateTime'],
  isVerified: Scalars['Boolean'],
  lastName: Scalars['String'],
  signedUpAt?: Maybe<Scalars['NaiveDateTime']>,
  status: IAccountStatus,
  updatedAt: Scalars['NaiveDateTime'],
};

export type IUserPayload = {
  __typename?: 'UserPayload',
  user: IUser,
};

export type IVariant = {
  __typename?: 'Variant',
  assets: Array<IAsset>,
  bulkDiscountPercentage?: Maybe<Scalars['Int']>,
  bulkDiscountQuantity?: Maybe<Scalars['Int']>,
  bulkPrice: Scalars['Decimal'],
  currency?: Maybe<Scalars['String']>,
  foreignId: Scalars['ID'],
  id: Scalars['ID'],
  insertedAt: Scalars['NaiveDateTime'],
  isBulkDiscount?: Maybe<Scalars['Boolean']>,
  isSale: Scalars['Boolean'],
  price: Scalars['Decimal'],
  product: IProduct,
  propertyValueIds: Array<Scalars['String']>,
  propertyValues: Array<IPropertyValue>,
  salePrice?: Maybe<Scalars['Decimal']>,
  skuAttributes?: Maybe<Scalars['String']>,
  stockLevel: Scalars['Int'],
  updatedAt: Scalars['NaiveDateTime'],
};
export type IProductSkuFragment = (
  { __typename?: 'AliexpressSku' }
  & Pick<IAliexpressSku, 'id' | 'skuCode' | 'currencyCode' | 'skuDisplayPrice' | 'offerSaleDisplayPrice' | 'skuAvailableStock' | 'skuStock'>
  & { skuProperties: Maybe<Array<{ __typename?: 'AliexpressProductSkuProperty' }
    & IProductSkuPropertyFragment
  >> }
);

export type IProductSkuPropertyFragment = (
  { __typename?: 'AliexpressProductSkuProperty' }
  & Pick<IAliexpressProductSkuProperty, 'skuPropertyValue' | 'propertyValueDefinitionName' | 'propertyValueIdLong' | 'skuPropertyId' | 'skuPropertyName' | 'skuImage'>
);

export type IProductFragment = (
  { __typename?: 'AliexpressProduct' }
  & Pick<IAliexpressProduct, 'productId' | 'subject' | 'detail' | 'productStatusType' | 'imageUrls' | 'createdAt'>
  & { productSkus: Maybe<Array<{ __typename?: 'AliexpressSku' }
    & IProductSkuFragment
  >> }
);

export type IGetProductByIdQueryVariables = {
  productId: Scalars['ID'],
  locale?: Maybe<Scalars['String']>,
  account?: Maybe<Scalars['String']>
};


export type IGetProductByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { product: { __typename?: 'AliexpressProduct' }
    & IProductFragment
   }
);
export const ProductSkuPropertyFragmentDoc = gql`
    fragment ProductSkuProperty on AliexpressProductSkuProperty {
  skuPropertyValue
  propertyValueDefinitionName
  propertyValueIdLong
  skuPropertyId
  skuPropertyName
  skuImage
}
    `;
export const ProductSkuFragmentDoc = gql`
    fragment ProductSku on AliexpressSku {
  id
  skuCode
  currencyCode
  skuDisplayPrice
  offerSaleDisplayPrice
  skuAvailableStock
  skuStock
  skuProperties {
    ...ProductSkuProperty
  }
}
    ${ProductSkuPropertyFragmentDoc}`;
export const ProductFragmentDoc = gql`
    fragment Product on AliexpressProduct {
  productId
  subject
  detail
  productStatusType
  imageUrls
  productSkus {
    ...ProductSku
  }
  createdAt
}
    ${ProductSkuFragmentDoc}`;
export const GetProductByIdDocument = gql`
    query GetProductById($productId: ID!, $locale: String = "en_EN", $account: String = "ali@koode.nl") {
  product: aliexpressProduct(email: $account, foreignId: $productId, locale: $locale) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;

    export function useGetProductByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetProductByIdQuery, IGetProductByIdQueryVariables>) {
      return ApolloReactHooks.useQuery<IGetProductByIdQuery, IGetProductByIdQueryVariables>(GetProductByIdDocument, baseOptions);
    };
      export function useGetProductByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetProductByIdQuery, IGetProductByIdQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<IGetProductByIdQuery, IGetProductByIdQueryVariables>(GetProductByIdDocument, baseOptions);
      };
      
export type GetProductByIdQueryHookResult = ReturnType<typeof useGetProductByIdQuery>;
export type GetProductByIdQueryResult = ApolloReactCommon.QueryResult<IGetProductByIdQuery, IGetProductByIdQueryVariables>;