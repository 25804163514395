import { Callout } from "@blueprintjs/core";
import React, { FC } from "react";
import { MainLayout } from "../../layouts/MainLayout";

export const KeywordSuccess: FC = () => {
  return (
    <MainLayout title="Product Discovery">
      <Callout intent="success" title="Successfully created Keyword Search">
        We'll be performing the keyword search and try to find products for it.
      </Callout>
    </MainLayout>
  );
};
