export default {
  graphql: {
    nesApi: {
      httpEndpoint:
        process.env.NODE_ENV === "production"
          ? "https://nes-api.koode.nl/graphql"
          : "http://localhost:4000/graphql",
      wsEndpoint:
        process.env.NODE_ENV === "production"
          ? "wss://nes-api.koode.nl/graphql"
          : "ws://localhost:4000/graphql"
    },
    productDiscovery: {
      httpEndpoint:
        process.env.NODE_ENV === "production" || true
          ? "https://nexus-google-product-discovery.koode.nl/graphql"
          : "http://localhost:5000/graphql"
    },
    nexusAliexpress: {
      httpEndpoint: "https://aliexpress-api.nexusapp.co/aliexpress/graphql"
    }
  }
};
