import React from "react";
import { Error } from "./Error";

interface IState {
  error?: Error;
}

export class ErrorBoundary extends React.Component<{}, IState> {
  public static getDerivedStateFromError(error: Error) {
    return { error };
  }

  public readonly state: IState = {};

  public componentDidCatch(error: Error, errorInfo?: React.ErrorInfo) {
    // TODO: Handle error
  }

  public render() {
    const { error } = this.state;
    const { children } = this.props;

    if (error) {
      return <Error />;
    }

    return children;
  }
}
