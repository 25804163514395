import { useEffect, useState } from "react";
import {
  IProductWithSimilarEntitiesCountFragment,
  useProductPendingLabelingAskDemandMutation,
  useProductPendingLabelingStartConsumerMutation,
  useProductPendingLabelingSubscription
} from "../../../graphql/nes-next/api";
import { preloadOptionImages } from "../preloadOptionImages";

const QUEUE_LENGTH = 10;

export const useConsumer = (userSlug: string | null) => {
  if (!userSlug) {
    throw new TypeError("useConsumer called without userSlug");
  }

  const [started, setStarted] = useState(false);
  const [error, setError] = useState(false);
  const [pendingDemand, setPendingDemand] = useState(0);
  const [queue, setQueue] = useState<
    IProductWithSimilarEntitiesCountFragment[]
  >([]);

  const [askDemand] = useProductPendingLabelingAskDemandMutation({
    variables: { userSlug },
    fetchPolicy: "no-cache"
  });
  const [startConsumer] = useProductPendingLabelingStartConsumerMutation({
    variables: { userSlug },
    fetchPolicy: "no-cache"
  });

  useProductPendingLabelingSubscription({
    variables: { userSlug },
    onSubscriptionData: ({ subscriptionData }) => {
      const { data, error: subscriptionError } = subscriptionData;

      if (subscriptionError) {
        setError(true);
      }

      if (!data) {
        setError(true);
        throw new Error(`Received empty frame, ${subscriptionData}`);
      }

      setPendingDemand(demand => demand - 1);
      setQueue(prevQueue => [...prevQueue, data.product]);
      preloadOptionImages(data.product);
    }
  });

  // Check pending demand
  useEffect(() => {
    if (!started) {
      return;
    }
    for (
      let demanded = pendingDemand + queue.length;
      demanded < QUEUE_LENGTH;
      demanded++
    ) {
      setPendingDemand(demand => demand + 1);
      askDemand();
    }
  }, [started, queue, pendingDemand, setPendingDemand, askDemand]);

  const handleNext = () => setQueue(prevQueue => prevQueue.slice(1));
  const handleStart = async () => {
    if (started) {
      setError(true);
      throw new Error("Consumer already started");
    }

    try {
      await startConsumer();
      setStarted(true);
    } catch (error) {
      setError(true);
    }
  };

  return { queue, start: handleStart, next: handleNext, started, error };
};
