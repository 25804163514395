import {
  IEntityTranslationAction,
  IProductWithSimilarEntitiesCountFragment
} from "../../../graphql/nes-next/api";
import { IEntityFormState } from "../ProductsQueue";

export function formStateToInputs(
  currentProduct: IProductWithSimilarEntitiesCountFragment,
  formState: IEntityFormState[]
) {
  return currentProduct.options
    .flatMap(option => option.entitiesWithSimilarCount)
    .map(res => res.entity)
    .map(entity => {
      const entityState = formState.find(
        ({ entityId }) => entityId === entity.id
      );

      return entityState
        ? entityState
        : {
            entityId: entity.id,
            value: entity.value,
            action: IEntityTranslationAction.UPDATE_ONE
          };
    });
}
