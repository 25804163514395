import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

const Img = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: center center;
`;

const Placeholder = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

interface IProps {
  src?: string | null;
  previewUrl?: string | null;
}

export const ProductImage: React.FC<IProps> = ({ src, previewUrl }) => {
  const [hover, setHover] = useState(false);
  const handleMouseEnter = useCallback(() => setHover(true), [setHover]);
  const handleMouseLeave = useCallback(() => setHover(false), [setHover]);
  const imageSrc = useMemo(() => (hover ? previewUrl : src), [
    hover,
    src,
    previewUrl
  ]);

  if (!imageSrc) {
    return (
      <Placeholder
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        No image
      </Placeholder>
    );
  }

  return (
    <Img
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      src={imageSrc}
    />
  );
};
