import React, { ReactNode, StatelessComponent } from "react";

import { Navbar } from "@blueprintjs/core";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface IProps {
  actions?: ReactNode;
  title: string;
  children?: ReactNode;
}

export const NavBar = styled(Navbar)`
  grid-area: navbar;
`;

export const Content = styled.main`
  grid-area: content;
  padding: 24px;
`;

export const Container = styled.div`
  display: grid;
  min-height: 100%;
  gap: 12px;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "navbar"
    "content";
`;

export const MainLayout: StatelessComponent<IProps> = ({
  title,
  actions,
  children
}) => {
  return (
    <Container>
      <NavBar>
        <Navbar.Group>
          <Navbar.Heading>
            <Link to="/">
              <strong>Moderation</strong>
            </Link>
          </Navbar.Heading>

          <Navbar.Divider />

          <span>{title}</span>
        </Navbar.Group>

        {actions && <Navbar.Group align="right">{actions}</Navbar.Group>}
      </NavBar>

      {children && <Content>{children}</Content>}
    </Container>
  );
};
