import { FormGroup, H3, Slider } from "@blueprintjs/core";
import { debounce } from "debounce";
import React, { FC, useEffect } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { ValueType } from "react-select/src/types";
import {
  countries,
  googleDomainOptions,
  IGoogleCountry,
  IGoogleLanguage,
  IGoogleLocation,
  languages
} from "../../data/serpApi";

// HACK: Typecasting is needed because of a bug related to ReadonlyArray<T>
// not narrowing the type correctly when using instanceof Array or
// Array.isArray type checking. https://github.com/microsoft/TypeScript/issues/17002
declare global {
  // tslint:disable-next-line: interface-name
  interface ArrayConstructor {
    isArray<T>(arg: ReadonlyArray<T> | any): arg is ReadonlyArray<T>;
  }
}

interface IProps {
  defaultLocations: IGoogleLocation[];
  estimatedResultCount: number;

  limit: number;
  onLimitChange: (limit: number) => void;

  location: IGoogleLocation | null;
  onLocationChange: (location: IGoogleLocation | null) => void;

  country: IGoogleCountry | null;
  onCountryChange: (country: IGoogleCountry | null) => void;

  language: IGoogleLanguage | null;
  onLanguageChange: (language: IGoogleLanguage | null) => void;
}

const fetchLocations = debounce(
  async (query: string, callback: (locations: IGoogleLocation[]) => void) => {
    const params = new URLSearchParams({ q: query, limit: "10" });

    callback(await (await fetch(`/api/serpapi/locations?${params}`)).json());
  },
  1000
);

const loadLocations = (
  query: string,
  callback: (locations: IGoogleLocation[]) => void
) => fetchLocations(query, callback);

const renderLocationLabel = (location: IGoogleLocation) =>
  `${location.name} (${location.canonical_name})`;
const renderLanguageLabel = (language: IGoogleLanguage) =>
  `${language.language_name} (${language.language_code})`;
const renderCountryLabel = (country: IGoogleCountry) =>
  `${country.country_name} (${country.country_code})`;

const getLocationValue = (option: IGoogleLocation) => option.id;
const getLanguageValue = (option: IGoogleLanguage) => option.language_code;
const getCountryValue = (option: IGoogleCountry) => option.country_code;

export const ShoppingSearchForm: FC<IProps> = ({
  defaultLocations,
  estimatedResultCount,
  limit,
  onLimitChange: handleLimitChange,
  location,
  onLocationChange,
  country,
  onCountryChange,
  language,
  onLanguageChange
}) => {
  // When the location changes, try to infer the country and language by finding
  // the corresponding Google Domain options
  useEffect(() => {
    const locationCountryCode = location && location.country_code.toLowerCase();
    const domainOptions = googleDomainOptions.find(
      domain => domain.countryCode === locationCountryCode
    );

    if (domainOptions) {
      const inferredCountry = countries.find(
        ({ country_code }) => domainOptions.countryCode === country_code
      );
      const inferredLanguage = languages.find(
        ({ language_code }) => domainOptions.languageCode === language_code
      );

      if (inferredCountry) {
        onCountryChange(inferredCountry);
      }

      if (inferredLanguage) {
        onLanguageChange(inferredLanguage);
      }
    }
  }, [location, onCountryChange, onLanguageChange]);

  const handleLocationChange = (value: ValueType<IGoogleLocation>) =>
    Array.isArray(value)
      ? onLocationChange(value[0])
      : onLocationChange(value || null);

  const handleCountryChange = (value: ValueType<IGoogleCountry>) =>
    Array.isArray(value)
      ? onCountryChange(value[0])
      : onCountryChange(value || null);

  const handleLanguageChange = (value: ValueType<IGoogleLanguage>) =>
    Array.isArray(value)
      ? onLanguageChange(value[0])
      : onLanguageChange(value || null);

  return (
    <>
      <H3>Shopping search</H3>

      <FormGroup
        label="Limit results per keyword"
        helperText={`How many products per keyword to retrieve. Estimated results: ${estimatedResultCount.toLocaleString(
          "nl"
        )} (total for all seeds)`}
      >
        <Slider
          min={0}
          max={20}
          labelStepSize={2}
          value={limit}
          onChange={handleLimitChange}
        />
      </FormGroup>

      <FormGroup
        label="Location"
        helperText="Define the location that this search needs to be performed from"
      >
        <AsyncSelect
          value={location}
          onChange={handleLocationChange}
          cacheOptions={true}
          defaultOptions={defaultLocations}
          filterOption={null}
          placeholder="e.g. Netherlands"
          loadOptions={loadLocations}
          getOptionLabel={renderLocationLabel}
          getOptionValue={getLocationValue}
        />
      </FormGroup>

      <FormGroup
        label="Language"
        helperText="The language that this search needs to be performed in"
      >
        <Select
          options={languages}
          onChange={handleLanguageChange}
          getOptionLabel={renderLanguageLabel}
          getOptionValue={getLanguageValue}
          value={language}
        />
      </FormGroup>

      <FormGroup
        label="Country"
        helperText="The country that this search needs to be performed in"
      >
        <Select
          options={countries}
          onChange={handleCountryChange}
          getOptionLabel={renderCountryLabel}
          getOptionValue={getCountryValue}
          value={country}
        />
      </FormGroup>
    </>
  );
};
