/* tslint:disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  /** 
 * The `Naive DateTime` scalar type represents a naive date and time without
   * timezone. The DateTime appears in a JSON response as an ISO8601 formatted
   * string.
 **/
  NaiveDateTime: any,
  /** Represents an uploaded file. */
  Upload: any,
};

export type IAliexpressPullingImage = {
  __typename?: 'AliexpressPullingImage',
  createdAt?: Maybe<Scalars['NaiveDateTime']>,
  doesContainText?: Maybe<Scalars['Boolean']>,
  fallbackImageId?: Maybe<Scalars['ID']>,
  id: Scalars['ID'],
  imageUrl: Scalars['String'],
  isBackgroundLight?: Maybe<Scalars['Boolean']>,
  optionValue?: Maybe<Scalars['String']>,
  parentImageId?: Maybe<Scalars['ID']>,
  type?: Maybe<Scalars['String']>,
  updatedAt?: Maybe<Scalars['NaiveDateTime']>,
};

export type IApproveModerationOptionsInput = {
  approveModerationOptionIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
  rejectModerationOptionIds?: Maybe<Array<Maybe<Scalars['ID']>>>,
};

export type ICreateSalesforceCaseInput = {
  description?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
  orderNumber?: Maybe<Scalars['String']>,
  phone?: Maybe<Scalars['String']>,
  reason?: Maybe<Scalars['String']>,
  subject?: Maybe<Scalars['String']>,
  webshop?: Maybe<Scalars['String']>,
};

export enum IEntityTranslationAction {
  UPDATE_ALL = 'UPDATE_ALL',
  UPDATE_ONE = 'UPDATE_ONE'
}

export type IEntityTranslationInput = {
  action: IEntityTranslationAction,
  entityId: Scalars['ID'],
  value: Scalars['String'],
};

export type IFulfillmentAddressInput = {
  address1: Scalars['String'],
  address2?: Maybe<Scalars['String']>,
  city: Scalars['String'],
  countryCode: Scalars['String'],
  fullName: Scalars['String'],
  mobileNumber: Scalars['String'],
  mobileNumberCountryPrefix: Scalars['String'],
  province?: Maybe<Scalars['String']>,
  zip: Scalars['String'],
};

export type IImage = {
  __typename?: 'Image',
  deletedAt?: Maybe<Scalars['NaiveDateTime']>,
  id: Scalars['ID'],
  nextImage?: Maybe<INextImage>,
  options?: Maybe<Array<IOption>>,
  product: IProduct,
  sourceUrl: Scalars['String'],
};

export type ILegacyAliexpressDeliveryWindow = {
  __typename?: 'LegacyAliexpressDeliveryWindow',
  avg: Scalars['Int'],
  max: Scalars['Int'],
  min: Scalars['Int'],
};

export type ILegacyAliexpressLogisticsService = {
  __typename?: 'LegacyAliexpressLogisticsService',
  deliveryWindow: ILegacyAliexpressDeliveryWindow,
  name: Scalars['String'],
  priceCents: Scalars['Int'],
};

export type ILegacyAliexpressOrder = {
  __typename?: 'LegacyAliexpressOrder',
  logisticsService: ILegacyAliexpressLogisticsService,
  orderId: Scalars['ID'],
  productPriceCents: Scalars['Int'],
};

export type ILegacyAliexpressPlaceOrderInput = {
  accountEmail: Scalars['String'],
  address: IFulfillmentAddressInput,
  force?: Maybe<Scalars['Boolean']>,
  itemId: Scalars['ID'],
  propertyValueIds: Array<Maybe<Scalars['Int']>>,
  quantity: Scalars['Int'],
  retailProductPriceCents: Scalars['Int'],
  shippingFeeCents: Scalars['Int'],
};

export type IModerateOptionsInput = {
  options: Array<IModerationOptionInput>,
};

export type IModerationActivityInput = {
  action?: Maybe<Scalars['String']>,
  optionId?: Maybe<Scalars['ID']>,
  userSlug?: Maybe<Scalars['String']>,
};

export type IModerationOption = {
  __typename?: 'ModerationOption',
  containsLogo?: Maybe<Scalars['Boolean']>,
  containsText?: Maybe<Scalars['Boolean']>,
  hasBackground?: Maybe<Scalars['Boolean']>,
  id: Scalars['ID'],
  image?: Maybe<INextImage>,
  imageBroken?: Maybe<Scalars['Boolean']>,
  logoCoversProduct?: Maybe<Scalars['Boolean']>,
  nesImageId?: Maybe<Scalars['ID']>,
  nesOptionId?: Maybe<Scalars['ID']>,
  option: IOption,
  resolveAction?: Maybe<IModerationOptionResolveAction>,
  status?: Maybe<IModerationOptionStatus>,
};

export type IModerationOptionInput = {
  containsLogo?: Maybe<Scalars['Boolean']>,
  containsText?: Maybe<Scalars['Boolean']>,
  hasBackground?: Maybe<Scalars['Boolean']>,
  imageBroken?: Maybe<Scalars['Boolean']>,
  logoCoversProduct?: Maybe<Scalars['Boolean']>,
  nesOptionId?: Maybe<Scalars['ID']>,
  status?: Maybe<IModerationOptionStatus>,
};

export enum IModerationOptionResolveAction {
  DELETE = 'DELETE',
  NONE = 'NONE',
  REPLACE = 'REPLACE',
  UPDATE = 'UPDATE'
}

export enum IModerationOptionStatus {
  CLAIMED = 'CLAIMED',
  FIXED = 'FIXED',
  FIXING = 'FIXING',
  PENDING = 'PENDING',
  PENDING_SCREENING = 'PENDING_SCREENING',
  SCREENED = 'SCREENED'
}


export type INextImage = {
  __typename?: 'NextImage',
  deletedAt?: Maybe<Scalars['NaiveDateTime']>,
  id?: Maybe<Scalars['ID']>,
  imageUrl: Scalars['String'],
  nesImageId?: Maybe<Scalars['ID']>,
  originalUrl: Scalars['String'],
  sourceUrl?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  url: Scalars['String'],
};

export type IOption = {
  __typename?: 'Option',
  aliexpressPullingImages?: Maybe<Array<IAliexpressPullingImage>>,
  baseCost?: Maybe<Scalars['Int']>,
  basePrice?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['NaiveDateTime']>,
  deletedAt?: Maybe<Scalars['NaiveDateTime']>,
  ean?: Maybe<Scalars['String']>,
  entities: Array<IOptionEntity>,
  entitiesWithSimilarCount: Array<IOptionEntityWithCount>,
  evaluation?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  image?: Maybe<IImage>,
  isEval?: Maybe<Scalars['Int']>,
  oldPricePercentage?: Maybe<Scalars['Int']>,
  product: IProduct,
  reviewed?: Maybe<Scalars['Boolean']>,
  sales?: Maybe<Scalars['Int']>,
  sku?: Maybe<Scalars['String']>,
  status?: Maybe<Scalars['String']>,
  stock?: Maybe<Scalars['Int']>,
  updatedAt?: Maybe<Scalars['NaiveDateTime']>,
};

export type IOptionEntity = {
  __typename?: 'OptionEntity',
  createdAt?: Maybe<Scalars['NaiveDateTime']>,
  deletedAt?: Maybe<Scalars['NaiveDateTime']>,
  entity: Scalars['String'],
  id: Scalars['ID'],
  updatedAt?: Maybe<Scalars['NaiveDateTime']>,
  value: Scalars['String'],
};

export type IOptionEntityWithCount = {
  __typename?: 'OptionEntityWithCount',
  count: Scalars['Int'],
  entity: IOptionEntity,
};

export type IOrder = {
  __typename?: 'Order',
  createdAt: Scalars['NaiveDateTime'],
  customerCountry: Scalars['String'],
  customerFullname: Scalars['String'],
  id: Scalars['ID'],
  number: Scalars['String'],
  orderItems: Array<IOrderItem>,
  shipmentPrice: Scalars['Int'],
};

export type IOrderItem = {
  __typename?: 'OrderItem',
  createdAt: Scalars['NaiveDateTime'],
  ean?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  product?: Maybe<IProduct>,
  quantity: Scalars['Int'],
  status: Scalars['String'],
  supplier?: Maybe<ISupplier>,
  supplierOrder?: Maybe<ISupplierOrder>,
  supplierOrderNumber?: Maybe<Scalars['String']>,
  trackingNumber?: Maybe<Scalars['String']>,
};

export type IProduct = {
  __typename?: 'Product',
  createdAt: Scalars['NaiveDateTime'],
  id: Scalars['ID'],
  images: Array<IImage>,
  options: Array<IOption>,
  source?: Maybe<Scalars['String']>,
  sourceDescription?: Maybe<Scalars['String']>,
  sourceName?: Maybe<Scalars['String']>,
  sourceOptions?: Maybe<Scalars['String']>,
  sourceUrl?: Maybe<Scalars['String']>,
  titleTranslations?: Maybe<Array<IProductTitleTranslation>>,
  titles: Array<IProductTitle>,
  updatedAt: Scalars['NaiveDateTime'],
};


export type IProductTitlesArgs = {
  filter?: Maybe<IProductTitleFilter>
};

export type IProductTitle = {
  __typename?: 'ProductTitle',
  createdAt?: Maybe<Scalars['NaiveDateTime']>,
  id: Scalars['ID'],
  placeholder?: Maybe<Scalars['String']>,
  product?: Maybe<IProduct>,
  sortOrder?: Maybe<Scalars['Int']>,
  translations?: Maybe<Array<IProductTitleTranslation>>,
  type?: Maybe<IProductTitleType>,
  updatedAt?: Maybe<Scalars['NaiveDateTime']>,
};


export type IProductTitleTranslationsArgs = {
  filter?: Maybe<IProductTitleTranslationFilter>
};

export type IProductTitleFilter = {
  type?: Maybe<IProductTitleType>,
};

export type IProductTitleTranslation = {
  __typename?: 'ProductTitleTranslation',
  createdAt?: Maybe<Scalars['NaiveDateTime']>,
  id: Scalars['ID'],
  language?: Maybe<Scalars['String']>,
  productTitle?: Maybe<IProductTitle>,
  updatedAt?: Maybe<Scalars['NaiveDateTime']>,
  value?: Maybe<Scalars['String']>,
};

export type IProductTitleTranslationFilter = {
  language?: Maybe<Scalars['String']>,
};

export enum IProductTitleType {
  LONG_DESCRIPTION = 'LONG_DESCRIPTION',
  META_DESCRIPTION = 'META_DESCRIPTION',
  META_TITLE = 'META_TITLE',
  PRIMARY_KEYWORD = 'PRIMARY_KEYWORD',
  PRIMARY_TITLE = 'PRIMARY_TITLE',
  SECONDARY_KEYWORD = 'SECONDARY_KEYWORD',
  SECONDARY_TITLE = 'SECONDARY_TITLE',
  SHORT_DESCRIPTION = 'SHORT_DESCRIPTION'
}

export type IResolveModerationOptionInput = {
  file?: Maybe<Scalars['Upload']>,
  id?: Maybe<Scalars['ID']>,
  imageId?: Maybe<Scalars['ID']>,
  resolveAction?: Maybe<IModerationOptionResolveAction>,
};

export type IRootMutationType = {
  __typename?: 'RootMutationType',
  approveModerationOptions?: Maybe<Scalars['Boolean']>,
  createSalesforceCase: ISalesforceCaseResponse,
  legacyAliexpressPlaceOrder?: Maybe<ILegacyAliexpressOrder>,
  moderateOptions?: Maybe<Scalars['Boolean']>,
  productPendingLabelingAskDemand?: Maybe<Scalars['Boolean']>,
  productPendingLabelingStartConsumer?: Maybe<Scalars['Boolean']>,
  productPendingLabelingTerminateConsumer?: Maybe<Scalars['Boolean']>,
  registerModerationActivity?: Maybe<Scalars['Boolean']>,
  rejectEntityTranslation: Scalars['Boolean'],
  resolveModerationOption?: Maybe<Scalars['Boolean']>,
  submitEntityTranslation: Scalars['Boolean'],
};


export type IRootMutationTypeApproveModerationOptionsArgs = {
  input: IApproveModerationOptionsInput
};


export type IRootMutationTypeCreateSalesforceCaseArgs = {
  input: ICreateSalesforceCaseInput
};


export type IRootMutationTypeLegacyAliexpressPlaceOrderArgs = {
  input: ILegacyAliexpressPlaceOrderInput
};


export type IRootMutationTypeModerateOptionsArgs = {
  input: IModerateOptionsInput
};


export type IRootMutationTypeProductPendingLabelingAskDemandArgs = {
  userSlug: Scalars['String']
};


export type IRootMutationTypeProductPendingLabelingStartConsumerArgs = {
  userSlug: Scalars['String']
};


export type IRootMutationTypeProductPendingLabelingTerminateConsumerArgs = {
  userSlug: Scalars['String']
};


export type IRootMutationTypeRegisterModerationActivityArgs = {
  input: IModerationActivityInput
};


export type IRootMutationTypeRejectEntityTranslationArgs = {
  productId: Scalars['ID'],
  userSlug: Scalars['String']
};


export type IRootMutationTypeResolveModerationOptionArgs = {
  input: IResolveModerationOptionInput
};


export type IRootMutationTypeSubmitEntityTranslationArgs = {
  inputs: Array<IEntityTranslationInput>,
  productId: Scalars['ID'],
  userSlug: Scalars['String']
};

export type IRootQueryType = {
  __typename?: 'RootQueryType',
  optionPendingModeration?: Maybe<IOption>,
  optionsPendingApproval?: Maybe<Array<IModerationOption>>,
  optionsPendingModeration?: Maybe<Array<IOption>>,
  optionsPendingRemoderation?: Maybe<Array<IModerationOption>>,
  orderByNumberAndPostalCode?: Maybe<IOrder>,
  shipmentsByIds: Array<IShipment>,
  validateOrderNumber: Scalars['Boolean'],
};


export type IRootQueryTypeOptionsPendingApprovalArgs = {
  count: Scalars['Int']
};


export type IRootQueryTypeOptionsPendingModerationArgs = {
  count: Scalars['Int']
};


export type IRootQueryTypeOptionsPendingRemoderationArgs = {
  count: Scalars['Int']
};


export type IRootQueryTypeOrderByNumberAndPostalCodeArgs = {
  orderNumber: Scalars['String'],
  postalCode: Scalars['String']
};


export type IRootQueryTypeShipmentsByIdsArgs = {
  ids?: Maybe<Array<Scalars['String']>>
};


export type IRootQueryTypeValidateOrderNumberArgs = {
  orderNumber: Scalars['String']
};

export type IRootSubscriptionType = {
  __typename?: 'RootSubscriptionType',
  productPendingLabeling: IProduct,
};


export type IRootSubscriptionTypeProductPendingLabelingArgs = {
  userSlug: Scalars['String']
};

export type ISalesforceCaseResponse = {
  __typename?: 'SalesforceCaseResponse',
  status: ISalesforceCaseStatus,
};

export enum ISalesforceCaseStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

export type IShipment = {
  __typename?: 'Shipment',
  destinationCountry?: Maybe<IShipmentCountry>,
  events: Array<IShipmentEvent>,
  id?: Maybe<Scalars['ID']>,
  originCountry?: Maybe<IShipmentCountry>,
  shippingTime?: Maybe<Scalars['Int']>,
  status?: Maybe<IShipmentStatus>,
  statusDescription?: Maybe<Scalars['String']>,
};

export type IShipmentCountry = {
  __typename?: 'ShipmentCountry',
  companyName?: Maybe<Scalars['String']>,
  companyUrl?: Maybe<Scalars['String']>,
  name?: Maybe<Scalars['String']>,
};

export type IShipmentEvent = {
  __typename?: 'ShipmentEvent',
  createdAt?: Maybe<Scalars['NaiveDateTime']>,
  description?: Maybe<Scalars['String']>,
  status?: Maybe<IShipmentStatus>,
};

export enum IShipmentStatus {
  ARRIVED_DESTINATION_COUNTRY = 'ARRIVED_DESTINATION_COUNTRY',
  ARRIVED_DESTINATION_COUNTRY_ERROR = 'ARRIVED_DESTINATION_COUNTRY_ERROR',
  DELIVERED = 'DELIVERED',
  DEPARTED_ORIGIN_COUNTRY = 'DEPARTED_ORIGIN_COUNTRY',
  DEPARTED_ORIGIN_COUNTRY_ERROR = 'DEPARTED_ORIGIN_COUNTRY_ERROR',
  IN_TRANSIT = 'IN_TRANSIT',
  INTERMEDIATE = 'INTERMEDIATE',
  NOT_FOUND = 'NOT_FOUND',
  PICKED_UP_BY_CARRIER = 'PICKED_UP_BY_CARRIER',
  RETURNED_TO_SENDER = 'RETURNED_TO_SENDER',
  SHIPPING_DEADLINE_EXPIRED = 'SHIPPING_DEADLINE_EXPIRED',
  UNKNOWN = 'UNKNOWN',
  UNSUCCESSFUL_DELIVERY = 'UNSUCCESSFUL_DELIVERY'
}

export enum ISupplier {
  ALIEXPRESS = 'ALIEXPRESS',
  BANGGOOD = 'BANGGOOD',
  JS_WEBSHOPS = 'JS_WEBSHOPS',
  UNKNOWN = 'UNKNOWN'
}

export type ISupplierOrder = {
  __typename?: 'SupplierOrder',
  id: Scalars['ID'],
  status: Scalars['String'],
  trackingCode?: Maybe<Scalars['String']>,
};

export type IProductPendingLabelingAskDemandMutationVariables = {
  userSlug: Scalars['String']
};


export type IProductPendingLabelingAskDemandMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<IRootMutationType, 'productPendingLabelingAskDemand'>
);

export type IProductPendingLabelingStartConsumerMutationVariables = {
  userSlug: Scalars['String']
};


export type IProductPendingLabelingStartConsumerMutation = (
  { __typename?: 'RootMutationType' }
  & Pick<IRootMutationType, 'productPendingLabelingStartConsumer'>
);

export type IRejectEntityTranslationsMutationVariables = {
  userSlug: Scalars['String'],
  productId: Scalars['ID']
};


export type IRejectEntityTranslationsMutation = (
  { __typename?: 'RootMutationType' }
  & { success: IRootMutationType['rejectEntityTranslation'] }
);

export type ISubmitEntityTranslationsMutationVariables = {
  userSlug: Scalars['String'],
  productId: Scalars['ID'],
  inputs: Array<IEntityTranslationInput>
};


export type ISubmitEntityTranslationsMutation = (
  { __typename?: 'RootMutationType' }
  & { success: IRootMutationType['submitEntityTranslation'] }
);

export type IProductPendingLabelingEntityFragment = (
  { __typename?: 'OptionEntity' }
  & Pick<IOptionEntity, 'id' | 'entity' | 'value'>
);

export type IProductPendingLabelingImageFragment = (
  { __typename?: 'Image' }
  & Pick<IImage, 'id' | 'sourceUrl'>
);

export type IProductPendingLabelingOptionFragment = (
  { __typename?: 'Option' }
  & Pick<IOption, 'id'>
  & { image: Maybe<{ __typename?: 'Image' }
    & IProductPendingLabelingImageFragment
  >, entitiesWithSimilarCount: Array<(
    { __typename?: 'OptionEntityWithCount' }
    & Pick<IOptionEntityWithCount, 'count'>
    & { entity: { __typename?: 'OptionEntity' }
      & IProductPendingLabelingEntityFragment
     }
  )> }
);

export type IProductWithSimilarEntitiesCountFragment = (
  { __typename?: 'Product' }
  & Pick<IProduct, 'id' | 'source' | 'sourceName' | 'sourceUrl'>
  & { options: Array<{ __typename?: 'Option' }
    & IProductPendingLabelingOptionFragment
  > }
);

export type IProductPendingLabelingSubscriptionVariables = {
  userSlug: Scalars['String']
};


export type IProductPendingLabelingSubscription = (
  { __typename?: 'RootSubscriptionType' }
  & { product: { __typename?: 'Product' }
    & IProductWithSimilarEntitiesCountFragment
   }
);
export const ProductPendingLabelingImageFragmentDoc = gql`
    fragment ProductPendingLabelingImage on Image {
  id
  sourceUrl
}
    `;
export const ProductPendingLabelingEntityFragmentDoc = gql`
    fragment ProductPendingLabelingEntity on OptionEntity {
  id
  entity
  value
}
    `;
export const ProductPendingLabelingOptionFragmentDoc = gql`
    fragment ProductPendingLabelingOption on Option {
  id
  image {
    ...ProductPendingLabelingImage
  }
  entitiesWithSimilarCount {
    count
    entity {
      ...ProductPendingLabelingEntity
    }
  }
}
    ${ProductPendingLabelingImageFragmentDoc}
${ProductPendingLabelingEntityFragmentDoc}`;
export const ProductWithSimilarEntitiesCountFragmentDoc = gql`
    fragment ProductWithSimilarEntitiesCount on Product {
  id
  source
  sourceName
  sourceUrl
  options {
    ...ProductPendingLabelingOption
  }
}
    ${ProductPendingLabelingOptionFragmentDoc}`;
export const ProductPendingLabelingAskDemandDocument = gql`
    mutation ProductPendingLabelingAskDemand($userSlug: String!) {
  productPendingLabelingAskDemand(userSlug: $userSlug)
}
    `;
export type IProductPendingLabelingAskDemandMutationFn = ApolloReactCommon.MutationFunction<IProductPendingLabelingAskDemandMutation, IProductPendingLabelingAskDemandMutationVariables>;

    export function useProductPendingLabelingAskDemandMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IProductPendingLabelingAskDemandMutation, IProductPendingLabelingAskDemandMutationVariables>) {
      return ApolloReactHooks.useMutation<IProductPendingLabelingAskDemandMutation, IProductPendingLabelingAskDemandMutationVariables>(ProductPendingLabelingAskDemandDocument, baseOptions);
    };
export type ProductPendingLabelingAskDemandMutationHookResult = ReturnType<typeof useProductPendingLabelingAskDemandMutation>;
export type ProductPendingLabelingAskDemandMutationResult = ApolloReactCommon.MutationResult<IProductPendingLabelingAskDemandMutation>;
export type ProductPendingLabelingAskDemandMutationOptions = ApolloReactCommon.BaseMutationOptions<IProductPendingLabelingAskDemandMutation, IProductPendingLabelingAskDemandMutationVariables>;
export const ProductPendingLabelingStartConsumerDocument = gql`
    mutation ProductPendingLabelingStartConsumer($userSlug: String!) {
  productPendingLabelingStartConsumer(userSlug: $userSlug)
}
    `;
export type IProductPendingLabelingStartConsumerMutationFn = ApolloReactCommon.MutationFunction<IProductPendingLabelingStartConsumerMutation, IProductPendingLabelingStartConsumerMutationVariables>;

    export function useProductPendingLabelingStartConsumerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IProductPendingLabelingStartConsumerMutation, IProductPendingLabelingStartConsumerMutationVariables>) {
      return ApolloReactHooks.useMutation<IProductPendingLabelingStartConsumerMutation, IProductPendingLabelingStartConsumerMutationVariables>(ProductPendingLabelingStartConsumerDocument, baseOptions);
    };
export type ProductPendingLabelingStartConsumerMutationHookResult = ReturnType<typeof useProductPendingLabelingStartConsumerMutation>;
export type ProductPendingLabelingStartConsumerMutationResult = ApolloReactCommon.MutationResult<IProductPendingLabelingStartConsumerMutation>;
export type ProductPendingLabelingStartConsumerMutationOptions = ApolloReactCommon.BaseMutationOptions<IProductPendingLabelingStartConsumerMutation, IProductPendingLabelingStartConsumerMutationVariables>;
export const RejectEntityTranslationsDocument = gql`
    mutation RejectEntityTranslations($userSlug: String!, $productId: ID!) {
  success: rejectEntityTranslation(userSlug: $userSlug, productId: $productId)
}
    `;
export type IRejectEntityTranslationsMutationFn = ApolloReactCommon.MutationFunction<IRejectEntityTranslationsMutation, IRejectEntityTranslationsMutationVariables>;

    export function useRejectEntityTranslationsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IRejectEntityTranslationsMutation, IRejectEntityTranslationsMutationVariables>) {
      return ApolloReactHooks.useMutation<IRejectEntityTranslationsMutation, IRejectEntityTranslationsMutationVariables>(RejectEntityTranslationsDocument, baseOptions);
    };
export type RejectEntityTranslationsMutationHookResult = ReturnType<typeof useRejectEntityTranslationsMutation>;
export type RejectEntityTranslationsMutationResult = ApolloReactCommon.MutationResult<IRejectEntityTranslationsMutation>;
export type RejectEntityTranslationsMutationOptions = ApolloReactCommon.BaseMutationOptions<IRejectEntityTranslationsMutation, IRejectEntityTranslationsMutationVariables>;
export const SubmitEntityTranslationsDocument = gql`
    mutation SubmitEntityTranslations($userSlug: String!, $productId: ID!, $inputs: [EntityTranslationInput!]!) {
  success: submitEntityTranslation(userSlug: $userSlug, productId: $productId, inputs: $inputs)
}
    `;
export type ISubmitEntityTranslationsMutationFn = ApolloReactCommon.MutationFunction<ISubmitEntityTranslationsMutation, ISubmitEntityTranslationsMutationVariables>;

    export function useSubmitEntityTranslationsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ISubmitEntityTranslationsMutation, ISubmitEntityTranslationsMutationVariables>) {
      return ApolloReactHooks.useMutation<ISubmitEntityTranslationsMutation, ISubmitEntityTranslationsMutationVariables>(SubmitEntityTranslationsDocument, baseOptions);
    };
export type SubmitEntityTranslationsMutationHookResult = ReturnType<typeof useSubmitEntityTranslationsMutation>;
export type SubmitEntityTranslationsMutationResult = ApolloReactCommon.MutationResult<ISubmitEntityTranslationsMutation>;
export type SubmitEntityTranslationsMutationOptions = ApolloReactCommon.BaseMutationOptions<ISubmitEntityTranslationsMutation, ISubmitEntityTranslationsMutationVariables>;
export const ProductPendingLabelingDocument = gql`
    subscription ProductPendingLabeling($userSlug: String!) {
  product: productPendingLabeling(userSlug: $userSlug) {
    ...ProductWithSimilarEntitiesCount
  }
}
    ${ProductWithSimilarEntitiesCountFragmentDoc}`;

    export function useProductPendingLabelingSubscription(baseOptions?: ApolloReactHooks.SubscriptionHookOptions<IProductPendingLabelingSubscription, IProductPendingLabelingSubscriptionVariables>) {
      return ApolloReactHooks.useSubscription<IProductPendingLabelingSubscription, IProductPendingLabelingSubscriptionVariables>(ProductPendingLabelingDocument, baseOptions);
    };
export type ProductPendingLabelingSubscriptionHookResult = ReturnType<typeof useProductPendingLabelingSubscription>;
export type ProductPendingLabelingSubscriptionResult = ApolloReactCommon.SubscriptionResult<IProductPendingLabelingSubscription>;