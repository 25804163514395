import React, { Dispatch, SetStateAction } from "react";

import { Divider, H3, Icon } from "@blueprintjs/core";
import { IProductWithSimilarEntitiesCountFragment as IProduct } from "../../graphql/nes-next/api";
import { Option, OptionGrid } from "./Option";
import { IEntityFormState } from "./ProductsQueue";

interface IProps {
  product: IProduct;
  formState: IEntityFormState[];
  setFormState: Dispatch<SetStateAction<IEntityFormState[]>>;
}

const PRODUCT_SOURCES: { [key: string]: string | null } = {
  aliexpress: "AliExpress",
  banggood: "Banggood"
};

export const Product: React.FunctionComponent<IProps> = props => {
  const { product, formState, setFormState } = props;

  const productSource =
    (product.source && PRODUCT_SOURCES[product.source]) ||
    product.source ||
    "Unknown";

  return (
    <div>
      <H3>
        {product.sourceUrl ? (
          <a
            href={product.sourceUrl.replace("http://", "https://")}
            target="_blank"
            rel="noopener noreferrer"
            title={`See product on ${productSource}`}
          >
            {product.sourceName} <Icon icon="share" />
          </a>
        ) : (
          product.sourceName
        )}
      </H3>
      <Divider />

      <OptionGrid>
        {product.options.map(option => (
          <Option
            key={option.id}
            option={option}
            formState={formState}
            setFormState={setFormState}
          />
        ))}
      </OptionGrid>
    </div>
  );
};
