import {
  AnchorButton,
  Button,
  Divider,
  FormGroup,
  H3,
  InputGroup,
  Tag
} from "@blueprintjs/core";
import React from "react";
import styled from "styled-components";
import { IProductFragment } from "../../graphql/nexus-aliexpress/api";
import { IShoppingResult } from "../../graphql/product-discovery/api";
import { ImageSelector } from "./ImageSelector";
import { OptionSelector } from "./OptionSelector";

const Wrapper = styled.div`
  display: block;
`;
const Row = styled.div`
  display: flex;
  justify-content: stretch;
`;
const RowPriceLink = styled(Row)`
  margin-bottom: 15px;
`;

interface IProps {
  shoppingResult: IShoppingResult;
  productUrl: string;
  imageUrl: string;
  retailPrice: string;
  aliexpressProduct: IProductFragment | null;
  aliexpressOptionId: string | null;
  onSubmit: () => void;
  onSkip: () => void;
  onProductUrlChange: (url: string) => void;
  onImageUrlChange: (url: string) => void;
  onRetailPriceChange: (price: string) => void;
  onAliexpressOptionIdChange: (optionId: string | null) => void;
}

export const ProductInfo: React.FC<IProps> = ({
  shoppingResult,
  productUrl,
  imageUrl,
  retailPrice,
  aliexpressProduct,
  aliexpressOptionId,
  onSubmit,
  onSkip,
  onProductUrlChange,
  onImageUrlChange,
  onRetailPriceChange,
  onAliexpressOptionIdChange: handleAliexpressOptionIdChange
}) => {
  const handleProductUrlChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();

    onProductUrlChange(event.currentTarget.value);
  };

  const handleImageUrlChange = (url: string | null | undefined) => {
    onImageUrlChange(url || "");
  };

  const handleRetailPriceChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();

    onRetailPriceChange(event.currentTarget.value);
  };

  return (
    <Wrapper>
      <Row>
        <H3>{shoppingResult.title || "Unknown product title"}</H3>
      </Row>

      <RowPriceLink>
        <Tag intent="danger" large={true} minimal={true}>
          {shoppingResult.price || "Unknown"}
        </Tag>

        <Divider />

        <AnchorButton
          href={shoppingResult.link!}
          target="_blank"
          rel="noopener noreferrer"
          minimal={true}
          intent="primary"
        >
          Visit product page
        </AnchorButton>
      </RowPriceLink>

      <Divider />

      <Row>
        <FormGroup
          label="AliExpress Product URL:"
          helperText="Try to find the product shown on AliExpress and enter the URL here. We will strip off any information that isn't needed."
        >
          <InputGroup
            value={productUrl}
            placeholder="e.g. https://aliexpress.com/item/123456.html"
            onChange={handleProductUrlChange}
          />
        </FormGroup>
      </Row>

      <Row>
        <FormGroup
          label="Retail Price:"
          helperText="Set the price (in EUR) that we sell it for, which should be higher than the cost of the product, but lower than the competitors."
        >
          <InputGroup
            value={retailPrice}
            placeholder="e.g. 10.99"
            onChange={handleRetailPriceChange}
          />
        </FormGroup>
      </Row>

      <div>
        {aliexpressProduct && (
          <ImageSelector
            onChange={handleImageUrlChange}
            imageUrl={imageUrl}
            product={aliexpressProduct}
          />
        )}
      </div>
      <div>
        {aliexpressProduct && (
          <OptionSelector
            onChange={handleAliexpressOptionIdChange}
            optionId={aliexpressOptionId}
            product={aliexpressProduct}
          />
        )}
      </div>

      <Row>
        <Button intent="primary" onClick={onSubmit}>
          Submit
        </Button>

        <Divider />

        <Button minimal={true} onClick={onSkip}>
          Skip
        </Button>
      </Row>
    </Wrapper>
  );
};
