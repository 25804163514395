import { NonIdealState } from "@blueprintjs/core";
import React, { useState } from "react";
import styled from "styled-components";
import { useGetProductByIdQuery } from "../../graphql/nexus-aliexpress/api";
import { client as aliexpressClient } from "../../graphql/nexus-aliexpress/client";
import {
  useCreateResolvedProductMutation,
  useGetShoppingResultByIdQuery,
  useGetShoppingResultQuery
} from "../../graphql/product-discovery/api";
import { client as productDiscoveryClient } from "../../graphql/product-discovery/client";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Loader } from "./Loader";
import { ProductImage } from "./ProductImage";
import { ProductInfo } from "./ProductInfo";

interface IProps {
  id?: string | null | undefined;
}

const imageUrlSizeRegex = /_(?<size>\d+?x\d+?(?:xz)?)\.\w+/;
export function normalizeImageUrl(
  imageUrl: string | null | undefined
): string | null {
  if (!imageUrl) {
    return null;
  }

  try {
    const url = new URL(imageUrl);
    url.search = "";
    return url.href.replace(imageUrlSizeRegex, "") || null;
  } catch {
    return null;
  }
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
`;

export const Product: React.FC<IProps> = ({ id }) => {
  const [userSlug] = useLocalStorage("user_slug");
  const [retailPrice, setRetailPrice] = useState<string>("");
  const [aliexpressOptionId, setAliexpressOptionId] = useState<string | null>(
    null
  );
  const [productUrl, setProductUrl] = useState<string>("");
  const [imageUrl, setImageUrl] = useState<string>("");
  const [productId, setProductId] = useState<string | null>(null);
  const {
    data: shoppingResultData,
    loading: shoppingResultLoading,
    refetch
  } = useGetShoppingResultQuery({
    skip: Boolean(id),
    client: productDiscoveryClient,
    variables: { userSlug }
  });
  const {
    data: shoppingResultByIdData,
    loading: byIdLoading
  } = useGetShoppingResultByIdQuery({
    skip: !Boolean(id),
    client: productDiscoveryClient,
    variables: { id: id! }
  });
  const [createResolvedProduct] = useCreateResolvedProductMutation({
    client: productDiscoveryClient
  });
  const { data: productByIdData } = useGetProductByIdQuery({
    client: aliexpressClient,
    skip: productId === null,
    variables: {
      productId: productId!
    }
  });

  if (shoppingResultLoading || byIdLoading) {
    return <Loader />;
  }

  const aliexpressProduct =
    (productId && (productByIdData && productByIdData.product)) || null;
  const shoppingResult = Boolean(id)
    ? (shoppingResultByIdData && shoppingResultByIdData.shoppingResult) || null
    : (shoppingResultData && shoppingResultData.shoppingResult) || null;
  if (!shoppingResult) {
    return <NonIdealState title="Unable to get shopping result" />;
  }

  const handleProductUrlChange = (url: string) => {
    const matches = url.match(
      /aliexpress\..*(?:\/|_)(?<productId>[0-9]+)\.html/i
    );
    if (matches && matches.groups && matches.groups.productId) {
      setProductId(matches.groups.productId);
      setProductUrl(
        `https://aliexpress.com/item/${matches.groups.productId}.html`
      );
    } else {
      setProductId(null);
      setProductUrl(url);
    }
  };

  const handleImageUrlChange = (url: string) => {
    setImageUrl(normalizeImageUrl(url) || "");
  };

  const handleRetailPriceChange = (price: string) => {
    setRetailPrice(price.replace(/[.,]/g, "."));
  };

  const handleAliexpressOptionIdChange = (optionId: string | null) => {
    setAliexpressOptionId(optionId);
  };

  const handleSkip = () => {
    setProductUrl("");
    setImageUrl("");
    refetch();
  };

  const handleSubmit = async () => {
    if (
      !productUrl ||
      !imageUrl ||
      retailPrice === "" ||
      // If no option id is set, but we do have a product with more than one option:
      (!aliexpressOptionId &&
        aliexpressProduct &&
        (aliexpressProduct.productSkus || []).length > 1)
    ) {
      return;
    }

    const aliexpressSku = (
      (aliexpressProduct && aliexpressProduct.productSkus) ||
      []
    ).find(option => option.id === aliexpressOptionId);

    const aliexpressOption =
      aliexpressSku && aliexpressSku.skuProperties
        ? aliexpressSku.skuProperties
            .map((prop, idx) => `sku-${idx + 1}-${prop.propertyValueIdLong}`)
            .join(",")
        : "";

    createResolvedProduct({
      variables: {
        input: {
          shoppingResultId: shoppingResult.id,
          gtin: shoppingResult.gtin,
          aliexpressUrl: productUrl,
          aliexpressOption,
          retailPrice,
          userSlug,
          imageUrl
        }
      }
    });
    setProductUrl("");
    setImageUrl("");
    setProductId(null);
    setAliexpressOptionId(null);
    setRetailPrice("");
    refetch();
  };

  return (
    <Wrapper>
      <ProductImage src={shoppingResult.thumbnail} previewUrl={imageUrl} />
      <ProductInfo
        shoppingResult={shoppingResult}
        aliexpressProduct={aliexpressProduct}
        aliexpressOptionId={aliexpressOptionId}
        productUrl={productUrl}
        imageUrl={imageUrl}
        retailPrice={retailPrice}
        onSubmit={handleSubmit}
        onSkip={handleSkip}
        onProductUrlChange={handleProductUrlChange}
        onImageUrlChange={handleImageUrlChange}
        onRetailPriceChange={handleRetailPriceChange}
        onAliexpressOptionIdChange={handleAliexpressOptionIdChange}
      />
    </Wrapper>
  );
};
