import {
  FormGroup,
  H3,
  NumberRange,
  RangeSlider,
  Slider,
  TagInput
} from "@blueprintjs/core";
import { OrderedSet } from "immutable";
import React, { FC } from "react";
import { siFormat } from "../../utils/siFormat";

interface IProps {
  keywords: OrderedSet<string>;
  onAddKeyword: (keywords: string[]) => void;
  onDeleteKeyword: (keywords: string) => void;

  limit: number;
  onLimitChange: (limit: number) => void;

  competitionRange: NumberRange;
  onCompetitionRangeChange: (competitionRange: NumberRange) => void;

  searchVolumeRange: NumberRange;
  onSearchVolumeRangeChange: (searchVolumeRange: NumberRange) => void;
}

const renderCompetitionLabel = (value: number) => {
  value = Math.round(value);

  if (value < 33) {
    return `${value}% (Low)`;
  }
  if (value < 66) {
    return `${value}% (Medium)`;
  }

  return `${value}% (High)`;
};

const renderSearchVolumeLabel = (value: number) => {
  if (value === 0) {
    return "0";
  }

  if (value === 3000) {
    return <>&infin;</>;
  }

  return `${siFormat(value, 1)}`;
};

export const KeywordForm: FC<IProps> = ({
  keywords,
  onAddKeyword: handleAddKeyword,
  onDeleteKeyword: handleDeleteKeyword,
  limit,
  onLimitChange: handleLimitChange,
  competitionRange,
  onCompetitionRangeChange: handleCompetitionRangeChange,
  searchVolumeRange,
  onSearchVolumeRangeChange: handleSearchVolumeRangeChange
}) => (
  <>
    <FormGroup
      label="Seed keywords"
      helperText="These keywords will be run through Google Keyword Planner to produce a larger list of keywords, so avoid using very similar keywords. Press enter to confirm a keyword."
    >
      <TagInput
        large={true}
        values={keywords.toArray()}
        onAdd={handleAddKeyword}
        onRemove={handleDeleteKeyword}
        placeholder="Enter Keywords..."
      />
    </FormGroup>

    <H3>Keyword search</H3>

    <FormGroup
      label="Competition"
      helperText="Set the minimum and maximum competition level for the generated keywords."
    >
      <RangeSlider
        min={0}
        max={100}
        stepSize={1}
        labelStepSize={50}
        value={competitionRange}
        onChange={handleCompetitionRangeChange}
        labelRenderer={renderCompetitionLabel}
      />
    </FormGroup>

    <FormGroup
      label="Search volume"
      helperText="Set the minimum and maximum search volumes that a keyword needs to have to be considered."
    >
      <RangeSlider
        min={0}
        max={3000}
        labelStepSize={500}
        value={searchVolumeRange}
        onChange={handleSearchVolumeRangeChange}
        labelRenderer={renderSearchVolumeLabel}
      />
    </FormGroup>

    <FormGroup
      label="Limit keywords"
      helperText={`How many keywords do we need to retrieve per seed keyword.`}
    >
      <Slider
        min={0}
        max={100}
        labelStepSize={20}
        value={limit}
        onChange={handleLimitChange}
      />
    </FormGroup>
  </>
);
