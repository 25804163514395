import { Button, Card, H4 } from "@blueprintjs/core";
import React, { FunctionComponent } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { MainLayout } from "../../layouts/MainLayout";

const CardList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
`;

export const LoggedInDashboard: FunctionComponent<RouteComponentProps> = ({
  history
}) => {
  const [userSlug, setUserSlug] = useLocalStorage("user_slug");

  const handleSignOutClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setUserSlug("");
  };

  const handleCardClick = (pathname: string) => (
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.preventDefault();
    history.push({ pathname });
  };

  const actions = (
    <span>
      Signed in as {userSlug},{" "}
      <Button minimal={true} onClick={handleSignOutClick}>
        sign out
      </Button>
    </span>
  );

  return (
    <MainLayout
      title="Choose a moderation type to get started"
      actions={actions}
    >
      <CardList>
        <Card
          interactive={true}
          elevation={1}
          onClick={handleCardClick("/moderate/entity")}
        >
          <H4>
            <Link to="/moderate/entity">Entity labeling</Link>
          </H4>
          <p>Label product options by describing their differences.</p>
          <Button>Start labeling product options</Button>
        </Card>

        <Card
          interactive={true}
          elevation={1}
          onClick={handleCardClick("/product-discovery/results")}
        >
          <H4>
            <Link to="/product-discovery/results">Product Discovery</Link>
          </H4>
          <p>Find similar products on AliExpress, and specify their link.</p>
          <Button>Start discoverying products</Button>
        </Card>
      </CardList>
    </MainLayout>
  );
};
