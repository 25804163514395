import { IProductWithSimilarEntitiesCountFragment as IProduct } from "../../graphql/nes-next/api";
import { preloadImage } from "../../utils/preloadImage";

export const preloadOptionImages = (product: IProduct) => {
  product.options.forEach(option => {
    if (option.image) {
      preloadImage(option.image.sourceUrl);
    }
  });
};
