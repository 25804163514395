// @ts-ignore
import { hasSubscription } from "@jumpn/utils-graphql";
import ApolloClient from "apollo-client";
import { split } from "apollo-link";

import { cache } from "./cache";
import { httpLink } from "./httpLink";
import { socketLink } from "./socketLink";

export const link = split(
  operation => hasSubscription(operation.query),
  socketLink,
  httpLink
);

export const client = new ApolloClient({
  cache,
  link
});
