import React from "react";
import { Redirect } from "react-router";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { ErrorBoundary } from "./ErrorBoundary";
import { ProductsQueue } from "./ProductsQueue";

export const EntityModeration: React.FunctionComponent = () => {
  const [userSlug] = useLocalStorage("user_slug");

  if (!userSlug) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <ErrorBoundary>
      <ProductsQueue />
    </ErrorBoundary>
  );
};
