import React from "react";
import { Redirect, RouteComponentProps } from "react-router";
import styled from "styled-components";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { MainLayout } from "../../layouts/MainLayout";
import { ErrorBoundary } from "../EntityModeration/ErrorBoundary";
import { Product } from "./Product";

const ProductGrid = styled.div`
  display: grid;
  max-width: 800px;
  margin-bottom: 24px;
  margin: 0 auto;
`;

interface IParams {
  id?: string;
}

export const ProductDiscoveryResult: React.FunctionComponent<
  RouteComponentProps<IParams>
> = ({ match }) => {
  const {
    params: { id }
  } = match;
  const [userSlug] = useLocalStorage("user_slug");

  if (!userSlug) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <ErrorBoundary>
      <MainLayout title="Google Product Discovery" actions={null}>
        <ProductGrid>
          <Product id={id || undefined} />
        </ProductGrid>
      </MainLayout>
    </ErrorBoundary>
  );
};
