import React from "react";

import styled from "styled-components";
import { IProductPendingLabelingImageFragment as IImage } from "../../graphql/nes-next/api";

interface IProps {
  image: IImage | null;
}

const Img = styled.img`
  max-width: 100%;
  min-height: 200px;
  max-height: 600px;
  object-fit: contain;
  object-position: center center;
`;

const Placeholder = styled.div`
  width: 100%;
  height: 300px;

  display: grid;
  align-content: center;
  justify-content: center;
`;

export const OptionImage: React.FunctionComponent<IProps> = props => {
  const { image } = props;

  if (!image) {
    return <Placeholder>No image</Placeholder>;
  }

  return <Img src={image.sourceUrl} />;
};
