import React, { Dispatch, Fragment, SetStateAction } from "react";

import {
  Card,
  FormGroup,
  InputGroup,
  Radio,
  RadioGroup
} from "@blueprintjs/core";
import styled from "styled-components";
import {
  IEntityTranslationAction,
  IProductPendingLabelingEntityFragment as IEntity,
  IProductPendingLabelingOptionFragment as IOption
} from "../../graphql/nes-next/api";
import { OptionImage } from "./OptionImage";
import { IEntityFormState } from "./ProductsQueue";

interface IProps {
  option: IOption;
  formState: IEntityFormState[];
  setFormState: Dispatch<SetStateAction<IEntityFormState[]>>;
}

export const OptionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
  margin-top: 12px;
`;

// const isSimpleEntityValue = (value: string) => {
//   if (value.match(/^.?\d{1,3}$/i)) {
//     return true;
//   }

//   if (value.match(/picture/i)) {
//     return true;
//   }

//   return false;
// };

export const Option: React.FunctionComponent<IProps> = props => {
  const { option, formState, setFormState } = props;

  const getEntityState = (state: IEntityFormState[], entity: IEntity) => {
    const entityState = state.find(es => es.entityId === entity.id);

    if (!entityState) {
      return {
        entityId: entity.id,
        value: entity.value,
        action: IEntityTranslationAction.UPDATE_ONE
      };
    }

    return entityState;
  };

  const setEntityState = (
    entity: IEntity,
    entityState: Partial<IEntityFormState>
  ) => {
    setFormState(oldFormState => {
      const oldEntityState = getEntityState(oldFormState, entity);

      return [
        ...oldFormState.filter(es => es !== oldEntityState),
        { ...oldEntityState, ...entityState }
      ];
    });
  };

  const handleEntityValueChange = (entity: IEntity) => (
    event: React.FormEvent<HTMLInputElement>
  ) => setEntityState(entity, { value: event.currentTarget.value });

  const handleEntityActionChange = (entity: IEntity) => (
    event: React.FormEvent<HTMLInputElement>
  ) =>
    setEntityState(entity, {
      action: event.currentTarget.value as IEntityTranslationAction
    });

  // FIXME: Disabled while we work on backend logic
  const enableBulkEdit = false;
  // const enableBulkEdit = count > 1 && !isSimpleEntityValue(entity.value);

  return (
    <Card elevation={1}>
      <OptionImage image={option.image} />

      {option.entitiesWithSimilarCount.map(({ entity, count }) => {
        const entityState = getEntityState(formState, entity);

        return (
          <Fragment key={entity.id}>
            <FormGroup
              label={`${entity.entity}:`}
              helperText={`Original: ${entity.value}`}
            >
              <InputGroup
                value={entityState.value}
                onChange={handleEntityValueChange(entity)}
              />
            </FormGroup>

            {enableBulkEdit && (
              <FormGroup
                label={`There are ${count} other options with the same value, apply this translation to:`}
                helperText={`Should '${entity.value}' always be changed to '${
                  entityState.value
                }'?`}
              >
                <RadioGroup
                  onChange={handleEntityActionChange(entity)}
                  selectedValue={entityState.action}
                >
                  <Radio
                    label="Only this option"
                    value={IEntityTranslationAction.UPDATE_ONE}
                  />

                  <Radio
                    label={`This and ${count} similar options`}
                    value={IEntityTranslationAction.UPDATE_ALL}
                  />
                </RadioGroup>
              </FormGroup>
            )}
          </Fragment>
        );
      })}
    </Card>
  );
};
