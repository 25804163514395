import { FormGroup } from "@blueprintjs/core";
import React, { FC, useMemo } from "react";
import Select from "react-select/creatable";
import { ValueType } from "react-select/src/types";
import { IProductFragment } from "../../graphql/nexus-aliexpress/api";

interface IOption {
  value: string;
  label: string;
}
interface IProps {
  onChange: (imageUrl: string | null | undefined) => void;
  imageUrl: string;
  product: IProductFragment;
}

const getOptionValue = (value: IOption) => value.value;
const formatOptionLabel = (value: IOption, meta: {context: "menu" | "value"}) => meta.context === "menu" ? <><img src={value.value} style={{maxWidth: "100%"}} alt=""/>{value.label}</> : value.label;
const getCreateLabel = (value: string) => value;

export const ImageSelector: FC<IProps> = ({ product, imageUrl, onChange }) => {
  const imageUrls = useMemo(() => product.imageUrls?.map(url => ({value: url, label: url})), [product]);
  const currentValue = useMemo(() => ({value: imageUrl, label: imageUrl}), [imageUrl]);

  const handleChange = (value: ValueType<IOption>, actionMeta: any) => {
    !value
      ? onChange(null)
      : Array.isArray(value)
      ? onChange(value[0].value)
      : onChange(value.value);
  };

  if (!imageUrls) {
    return <div>No options selectable</div>;
  }

  return (
    <FormGroup
      label="Select product image or enter URL:"
      helperText="Copy a good image URL from AliExpress that does not contain any logos or background."
    >
      <Select
        value={currentValue}
        formatCreateLabel={getCreateLabel}
        options={imageUrls}
        onChange={handleChange}
        formatOptionLabel={formatOptionLabel}
        getOptionValue={getOptionValue}
      />
    </FormGroup>
  );
};
