// @ts-ignore
import * as AbsintheSocket from "@absinthe/socket";
// @ts-ignore
import { createAbsintheSocketLink } from "@absinthe/socket-apollo-link";
import { Socket as PhoenixSocket } from "phoenix";
import config from "../../config";

const phoenixSocket = new PhoenixSocket(config.graphql.nesApi.wsEndpoint);
const absintheSocket = AbsintheSocket.create(phoenixSocket);
export const socketLink = createAbsintheSocketLink(absintheSocket);
