import { NonIdealState, Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";

interface IProps {
  timeout?: number;
}

export const Loader: React.FC<IProps> = ({ timeout = 5000 }) => {
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setTimedOut(true), timeout);

    return () => clearTimeout(timer);
  });

  if (timedOut) {
    return (
      <NonIdealState
        title="This doesn't seem right..."
        description="This is taking longer than expected, please check your connection and if this persists, contact development."
        icon="error"
      />
    );
  }

  return (
    <NonIdealState
      title="Getting next shopping result..."
      description="This shouldn't take long."
      icon={<Spinner />}
    />
  );
};
