import { useEffect, useState } from "react";

export const useLocalStorage = (
  key: string
): [string | null, (key: string) => void] => {
  const [localValue, setLocalValue] = useState(localStorage.getItem(key));

  const handleStorageEvent = (event: StorageEvent) => {
    if (event.key === key) {
      setLocalValue(event.newValue);
    }
  };

  const setValue = (value: string) => {
    // Mimick the storage event for the current tab
    window.dispatchEvent(
      new StorageEvent("storage", {
        key,
        oldValue: localValue,
        newValue: value
      })
    );

    localStorage.setItem(key, value);
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageEvent);

    return () => window.removeEventListener("storage", handleStorageEvent);
  });

  return [localValue, setValue];
};
