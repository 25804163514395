/* tslint:disable */
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
};

export type IKeywordSearch = {
  __typename?: 'KeywordSearch',
  id: Scalars['ID'],
  keyword?: Maybe<Scalars['String']>,
  location?: Maybe<Scalars['String']>,
  maximumCompetition?: Maybe<Scalars['Float']>,
  maximumSearchVolume?: Maybe<Scalars['Int']>,
  minimumCompetition?: Maybe<Scalars['Float']>,
  minimumSearchVolume?: Maybe<Scalars['Int']>,
  resultLimit?: Maybe<Scalars['Int']>,
  shoppingSearches?: Maybe<Array<Maybe<IShoppingSearch>>>,
};

export type IKeywordSearchInput = {
  keyword: Scalars['String'],
  /** locale code, eg: `en_us` */
  location?: Maybe<Scalars['String']>,
  /** maximum competition, max = 1 */
  maximumCompetition?: Maybe<Scalars['Float']>,
  maximumSearchVolume?: Maybe<Scalars['Int']>,
  /** minimum competition, min = 0 */
  minimumCompetition?: Maybe<Scalars['Float']>,
  minimumSearchVolume?: Maybe<Scalars['Int']>,
  /** How many similar keywords to fetch from Google Adwords API. */
  resultLimit: Scalars['Int'],
  shoppingSearch: IShoppingSearchInput,
};

export type IResolvedProductInput = {
  aliexpressOption?: Maybe<Scalars['String']>,
  aliexpressUrl?: Maybe<Scalars['String']>,
  gtin?: Maybe<Scalars['String']>,
  imageUrl?: Maybe<Scalars['String']>,
  retailPrice?: Maybe<Scalars['String']>,
  shoppingResultId?: Maybe<Scalars['ID']>,
  userSlug?: Maybe<Scalars['String']>,
};

export type IRootMutationType = {
  __typename?: 'RootMutationType',
  pushKeywordSearch?: Maybe<IKeywordSearch>,
  pushResolvedProduct?: Maybe<Scalars['Boolean']>,
};


export type IRootMutationTypePushKeywordSearchArgs = {
  input?: Maybe<IKeywordSearchInput>
};


export type IRootMutationTypePushResolvedProductArgs = {
  input?: Maybe<IResolvedProductInput>
};

export type IRootQueryType = {
  __typename?: 'RootQueryType',
  shoppingResult?: Maybe<IShoppingResult>,
  shoppingResultById?: Maybe<IShoppingResult>,
};


export type IRootQueryTypeShoppingResultArgs = {
  userSlug?: Maybe<Scalars['String']>
};


export type IRootQueryTypeShoppingResultByIdArgs = {
  id: Scalars['ID']
};

export type IShoppingResult = {
  __typename?: 'ShoppingResult',
  extensions?: Maybe<Array<Maybe<Scalars['String']>>>,
  gtin?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  link?: Maybe<Scalars['String']>,
  position?: Maybe<Scalars['Int']>,
  price?: Maybe<Scalars['String']>,
  rating?: Maybe<Scalars['Float']>,
  reviews?: Maybe<Scalars['Int']>,
  snippet?: Maybe<Scalars['String']>,
  thumbnail?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
};

export type IShoppingSearch = {
  __typename?: 'ShoppingSearch',
  country?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  isPrototype?: Maybe<Scalars['Boolean']>,
  keyword?: Maybe<Scalars['String']>,
  language?: Maybe<Scalars['String']>,
  location?: Maybe<Scalars['String']>,
  resultLimit?: Maybe<Scalars['Int']>,
};

export type IShoppingSearchInput = {
  /** two-letter country code, eg: `uk` for United Kingdom */
  country?: Maybe<Scalars['String']>,
  /** two-letter language code, eg: `fr` for French */
  language?: Maybe<Scalars['String']>,
  /** any location (free-form), eg: `Amsterdam, Netherlands` */
  location?: Maybe<Scalars['String']>,
  /** The number of shopping results to fetch. */
  resultLimit: Scalars['Int'],
};
export type IPushKeywordSearchMutationVariables = {
  input?: Maybe<IKeywordSearchInput>
};


export type IPushKeywordSearchMutation = (
  { __typename?: 'RootMutationType' }
  & { pushKeywordSearch: Maybe<(
    { __typename?: 'KeywordSearch' }
    & Pick<IKeywordSearch, 'id'>
  )> }
);

export type ICreateResolvedProductMutationVariables = {
  input: IResolvedProductInput
};


export type ICreateResolvedProductMutation = (
  { __typename?: 'RootMutationType' }
  & { success: IRootMutationType['pushResolvedProduct'] }
);

export type IGetShoppingResultQueryVariables = {
  userSlug?: Maybe<Scalars['String']>
};


export type IGetShoppingResultQuery = (
  { __typename?: 'RootQueryType' }
  & { shoppingResult: Maybe<(
    { __typename?: 'ShoppingResult' }
    & Pick<IShoppingResult, 'id' | 'title' | 'thumbnail' | 'gtin' | 'link' | 'price' | 'snippet'>
  )> }
);

export type IGetShoppingResultByIdQueryVariables = {
  id: Scalars['ID']
};


export type IGetShoppingResultByIdQuery = (
  { __typename?: 'RootQueryType' }
  & { shoppingResult: Maybe<(
    { __typename?: 'ShoppingResult' }
    & Pick<IShoppingResult, 'id' | 'title' | 'thumbnail' | 'gtin' | 'link' | 'price' | 'snippet'>
  )> }
);

export const PushKeywordSearchDocument = gql`
    mutation PushKeywordSearch($input: KeywordSearchInput) {
  pushKeywordSearch(input: $input) {
    id
  }
}
    `;
export type IPushKeywordSearchMutationFn = ApolloReactCommon.MutationFunction<IPushKeywordSearchMutation, IPushKeywordSearchMutationVariables>;

    export function usePushKeywordSearchMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<IPushKeywordSearchMutation, IPushKeywordSearchMutationVariables>) {
      return ApolloReactHooks.useMutation<IPushKeywordSearchMutation, IPushKeywordSearchMutationVariables>(PushKeywordSearchDocument, baseOptions);
    };
export type PushKeywordSearchMutationHookResult = ReturnType<typeof usePushKeywordSearchMutation>;
export type PushKeywordSearchMutationResult = ApolloReactCommon.MutationResult<IPushKeywordSearchMutation>;
export type PushKeywordSearchMutationOptions = ApolloReactCommon.BaseMutationOptions<IPushKeywordSearchMutation, IPushKeywordSearchMutationVariables>;
export const CreateResolvedProductDocument = gql`
    mutation CreateResolvedProduct($input: ResolvedProductInput!) {
  success: pushResolvedProduct(input: $input)
}
    `;
export type ICreateResolvedProductMutationFn = ApolloReactCommon.MutationFunction<ICreateResolvedProductMutation, ICreateResolvedProductMutationVariables>;

    export function useCreateResolvedProductMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ICreateResolvedProductMutation, ICreateResolvedProductMutationVariables>) {
      return ApolloReactHooks.useMutation<ICreateResolvedProductMutation, ICreateResolvedProductMutationVariables>(CreateResolvedProductDocument, baseOptions);
    };
export type CreateResolvedProductMutationHookResult = ReturnType<typeof useCreateResolvedProductMutation>;
export type CreateResolvedProductMutationResult = ApolloReactCommon.MutationResult<ICreateResolvedProductMutation>;
export type CreateResolvedProductMutationOptions = ApolloReactCommon.BaseMutationOptions<ICreateResolvedProductMutation, ICreateResolvedProductMutationVariables>;
export const GetShoppingResultDocument = gql`
    query GetShoppingResult($userSlug: String) {
  shoppingResult(userSlug: $userSlug) {
    id
    title
    thumbnail
    gtin
    link
    price
    snippet
  }
}
    `;

    export function useGetShoppingResultQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetShoppingResultQuery, IGetShoppingResultQueryVariables>) {
      return ApolloReactHooks.useQuery<IGetShoppingResultQuery, IGetShoppingResultQueryVariables>(GetShoppingResultDocument, baseOptions);
    };
      export function useGetShoppingResultLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetShoppingResultQuery, IGetShoppingResultQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<IGetShoppingResultQuery, IGetShoppingResultQueryVariables>(GetShoppingResultDocument, baseOptions);
      };
      
export type GetShoppingResultQueryHookResult = ReturnType<typeof useGetShoppingResultQuery>;
export type GetShoppingResultQueryResult = ApolloReactCommon.QueryResult<IGetShoppingResultQuery, IGetShoppingResultQueryVariables>;
export const GetShoppingResultByIdDocument = gql`
    query GetShoppingResultById($id: ID!) {
  shoppingResult: shoppingResultById(id: $id) {
    id
    title
    thumbnail
    gtin
    link
    price
    snippet
  }
}
    `;

    export function useGetShoppingResultByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IGetShoppingResultByIdQuery, IGetShoppingResultByIdQueryVariables>) {
      return ApolloReactHooks.useQuery<IGetShoppingResultByIdQuery, IGetShoppingResultByIdQueryVariables>(GetShoppingResultByIdDocument, baseOptions);
    };
      export function useGetShoppingResultByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IGetShoppingResultByIdQuery, IGetShoppingResultByIdQueryVariables>) {
        return ApolloReactHooks.useLazyQuery<IGetShoppingResultByIdQuery, IGetShoppingResultByIdQueryVariables>(GetShoppingResultByIdDocument, baseOptions);
      };
      
export type GetShoppingResultByIdQueryHookResult = ReturnType<typeof useGetShoppingResultByIdQuery>;
export type GetShoppingResultByIdQueryResult = ApolloReactCommon.QueryResult<IGetShoppingResultByIdQuery, IGetShoppingResultByIdQueryVariables>;