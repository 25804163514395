import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { EntityModeration } from "./EntityModeration/EntityModeration";
import { ModerationDashboard } from "./ModerationDashboard/ModerationDashboard";
import { KeywordSuccess } from "./ProductDiscoveryKeywords/KeywordSuccess";
import { ProductDiscoveryKeywords } from "./ProductDiscoveryKeywords/ProductDiscoveryKeywords";
import { ProductDiscoveryResult } from "./ProductDiscoveryResults/ProductDiscoveryResult";

export const Router: React.FunctionComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/moderate/entity"
          component={EntityModeration}
          exact={true}
        />
        <Route
          path="/product-discovery/results/:id"
          component={ProductDiscoveryResult}
          exact={true}
        />
        <Route
          path="/product-discovery/results"
          component={ProductDiscoveryResult}
          exact={true}
        />
        <Route
          path="/product-discovery/keywords"
          component={ProductDiscoveryKeywords}
          exact={true}
        />
        <Route
          path="/product-discovery/keywords/success"
          component={KeywordSuccess}
          exact={true}
        />
        <Route path="/" component={ModerationDashboard} exact={true} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};
