import React, { useEffect, useState } from "react";

import { NonIdealState, Spinner } from "@blueprintjs/core";

interface IProps {
  timeout?: number;
}

export const Loader: React.FunctionComponent<IProps> = ({ timeout = 5000 }) => {
  const [timedOut, setTimedOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setTimedOut(true), timeout);

    return () => clearTimeout(timer);
  });

  if (timedOut) {
    return (
      <NonIdealState
        title="This doesn't seem right..."
        description="This is taking longer than expected, maybe there are no tasks available now, check back later. If this persists, please let us know."
        icon="error"
      />
    );
  }

  return (
    <NonIdealState
      title="Loading tasks..."
      description="We're loading some tasks for you, please hold on."
      icon={<Spinner />}
    />
  );
};
